import BarChart from "./BarChart.vue";
import CustomLineChart from "./LineChart.vue";
import CustomPieChart from "./PieChart.vue";

import api from "./api.js";
import settings from "../../plugins/settings.js";

var trip_term = localStorage.getItem("trip_term") || "Trip";
var driver_term = localStorage.getItem("driver_term") || "Driver";
var currency = settings.fromNameToSymbol(
  localStorage.getItem("currency") || "Euro"
);

export default {
  components: {
    BarChart,
    CustomLineChart,
    CustomPieChart,
  },
  data() {
    return {
      intervalId: null,
      search: "",
      fdate: "",
      ldate: "",
      value: [],
      labels: [],
      menu2: false,
      menu1: false,
      snackbarMessage: "",
      snackbar: false,
      moreBar: true,
      moreLine: true,
      driver_term: driver_term,
      trip_term: trip_term,
      driverOptions: [1, 2, 5, 10, 15, 20, "All"],
      selectedDrivers: 10,
      loading: false,
      currency: currency,
      showNoDataPie: false,
      showNoDataBar: false,
      showNoDataLine: false,
      noDataMessage: "No significant data available to display at the moment.",
      data: [],

      tableHeaders: [
        {
          text: "Full Name",
          align: "center",
          value: "full_name",
        },
        {
          text: "Total Working Hours (hh:mm:ss)",
          align: "center",
          value: "total_time",
        },
        {
          text: "Total Rate",
          align: "center",
          value: "total_rate",
        },
      ],
      tableData: [],

      excTableHeaders: [
        {
          text: "Full name",
          align: "center",
          value: "full_name",
        },
        {
          text: "Total Exceptions",
          align: "center",
          value: "exception_count",
        },
      ],

      destinationsChartData: {
        labels: [],
        datasets: [],
      },
      workingHoursChartDataLine: {
        labels: [],
        datasets: [],
      },
      workingHoursChartDataPie: {
        labels: [],
        datasets: [],
      },

      pieChartOptions: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.label}: ${tooltipItem.raw}`;
              },
            },
          },
        },
      },
    };
  },
  methods: {
    onFDateInput() {
      this.menu1 = false;
      this.$nextTick(() => {
        this.fetchAllData();
      });
    },
    onLDateInput() {
      this.menu2 = false;
      this.$nextTick(() => {
        this.fetchAllData();
      });
    },
    showMore(from) {
      switch (from) {
        case "Bar":
          this.moreBar = !this.moreBar;
          this.fetchDriversWithMostTrips();
          break;
        case "Line":
          this.moreLine = !this.moreLine;
          this.fetchDriversWorkingHoursLineChart();
          break;
      }
    },
    resetDates() {
      this.fdate = "";
      this.ldate = "";
      this.fetchAllData();
    },
    async fetchDriversWithMostTrips() {
      try {
        const res = await api.getDriversWithMostTrips(this.fdate, this.ldate);
        if (res.data.responseCode == 1) {
          let drivers = res.data.data;

          if (this.moreBar) {
            drivers = drivers.slice(0, 6);
          }

          this.destinationsChartData = {
            labels: drivers.map((driver) => `${driver.fname} ${driver.lname}`),
            datasets: [
              {
                label: trip_term + " Completed",
                data: drivers.map((driver) => driver.trip_count),
                backgroundColor: "rgba(56, 162, 235, 0.2)",
                borderColor: "rgb(54, 162, 235)",
                borderWidth: 1,
              },
            ],
          };

          this.showNoDataBar = !drivers.some((x) => x.trip_count != 0);
        } else {
          this.destinationsChartData = {
            labels: [],
            datasets: [],
          };
          this.$snackbar.show(
            res.data.message || "No data available",
            "success"
          );
          this.showNoDataBar = false;
        }
      } catch (err) {
        this.$snackbar.show(
          `Error fetching drivers with most ${trip_term}s.`,
          "error"
        );
      }
    },

    async fetchDriversWithMostExceptions() {
      try {
        const res = await api.getDriversWithMostExceptions(
          this.fdate,
          this.ldate
        );
        if (res.data.responseCode == 1) {
          this.data = res.data.data.map((driver) => {
            return {
              ...driver,
              full_name: `${driver.fname} ${driver.lname}`,
            };
          });
        }
      } catch (err) {
        this.$snackbar.show(
          "Error fetching drivers with most Exceptions.",
          "error"
        );
      }
    },

    async fetchDriversWorkingHoursLineChart() {
      try {
        const res = await api.getDriversWithMostWorkingHours(
          this.fdate,
          this.ldate
        );

        if (res.data.responseCode === 1) {
          let drivers = res.data.data;

          const driversForLineChart = this.moreLine
            ? drivers.slice(0, 6)
            : drivers;

          this.workingHoursChartDataLine = {
            labels: driversForLineChart.map(
              (driver) => `${driver.fname} ${driver.lname}`
            ),
            datasets: [
              {
                label: "Working Hours",
                data: driversForLineChart.map((driver) =>
                  parseInt(driver.total_time.split(":")[0], 10)
                ),
                backgroundColor: "rgba(56, 162, 235, 0.2)",
                borderColor: "rgb(54, 162, 235)",
                borderWidth: 1,
              },
            ],
          };

          this.showNoDataLine = !driversForLineChart
            .map((driver) => parseInt(driver.total_time.split(":")[0], 10))
            .some((x) => x != 0);
        } else {
          this.workingHoursChartDataLine = { labels: [], datasets: [] };
          this.showNoDataLine = false;
          this.$snackbar.show(
            res.data.message ||
              "No data available for working hours (Line Chart).",
            "success"
          );
        }
      } catch (err) {
        this.workingHoursChartDataLine = { labels: [], datasets: [] };
        console.error(
          "Error fetching drivers with most working hours for Line Chart:",
          err
        );
        this.$snackbar.show(
          err || "Error fetching working hours for Line Chart.",
          "error"
        );
      }
    },

    async fetchDriversWorkingHoursPieChart() {
      try {
        this.loading = true;
        const res = await api.getDriversWithMostWorkingHours(
          this.fdate,
          this.ldate
        );

        if (res.data.responseCode === 1) {
          let drivers = res.data.data;

          let driversForPieChart;

          if (this.selectedDrivers == "All") {
            driversForPieChart = drivers;
          } else {
            driversForPieChart = drivers.slice(0, this.selectedDrivers);
          }
          this.tableData = driversForPieChart.map((driver) => {
            return {
              ...driver,
              full_name: `${driver.fname} ${driver.lname}`,
            };
          });

          this.workingHoursChartDataPie = {
            labels: driversForPieChart.map(
              (driver) => `${driver.fname} ${driver.lname}`
            ),
            datasets: [
              {
                label: "Working Hours",
                data: driversForPieChart.map((driver) =>
                  parseInt(driver.total_time.split(":")[0], 10)
                ),
                backgroundColor: driversForPieChart.map(
                  () =>
                    `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
                      Math.random() * 255
                    )}, ${Math.floor(Math.random() * 255)}, 0.7)`
                ),
              },
            ],
          };
          this.showNoDataPie = !driversForPieChart
            .map((driver) => parseInt(driver.total_time.split(":")[0], 10))
            .some((x) => x != 0);
        } else {
          this.workingHoursChartDataPie = { labels: [], datasets: [] };
          this.showNoDataPie = false;
          this.$snackbar.show(
            res.data.message ||
              "No data available for working hours (Pie Chart).",
            "success"
          );
        }
      } catch (err) {
        this.workingHoursChartDataPie = { labels: [], datasets: [] };
        this.$snackbar.show(
          "Error fetching working hours for Pie Chart.",
          "error"
        );
        console.error(
          "Error fetching drivers with most working hours for Pie Chart:",
          err
        );
      } finally {
        this.loading = false;
      }
    },

    convertTimeToHours(timeString) {
      if (!timeString) return 0;
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return hours + minutes / 60 + seconds / 3600;
    },
    fetchAllData() {
      this.fetchDriversWithMostTrips();
      this.fetchDriversWorkingHoursLineChart();
      this.fetchDriversWorkingHoursPieChart();
      this, this.fetchDriversWithMostExceptions();
    },
  },
  async mounted() {
    await this.fetchAllData();

    this.intervalId = setInterval(() => {
      this.fetchAllData();
    }, 10000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
