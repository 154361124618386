import api from "./api.js";
import settings from "../../plugins/settings.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";
var trip_term = localStorage.getItem("trip_term") || "Trip";
var currency = settings.fromNameToSymbol(
  localStorage.getItem("currency") || "Euro"
);
var volume_unit = settings.fromNameToSymbol(
  localStorage.getItem("liquid_unit"),
  "liquidUnit"
);

export default {
  data: () => ({
    intervalId: null,
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    search: "",
    selected_driver: null,
    driversData: [{ fullname: "ALL", id: -1 }],
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    show1: false,
    show2: false,
    trip_term: trip_term,
    driver_term: driver_term,
    currency: currency,
    volume_unit: volume_unit,
    myCurrentData: [],
    data: [],
    json_fields: {
      Id: "driver.id",
      FullName: "fullname",
      "E-mail": "driver.email",
      "Total Working Hours": "TotalWorkingHours",
      "Hourly Rate": "driver.hourly_rate",
      "Total Amount": "totalAmmount",
      Accidents: "totalAccidents",
      "Fuel Consumption": "totalFuel",
      [`${trip_term}s`]: "totalTrips",
      "Fuel Amount": "TotalDieselAmount",
    },
    headers: [
      {
        text: "Full Name",
        align: "center",
        value: "fullname",
      },
      {
        text: "E-mail",
        align: "center",
        value: "driver.email",
      },
      {
        text: "Total Working Hours",
        align: "center",
        value: "TotalWorkingHours",
      },
      {
        text: "Nb of " + trip_term,
        align: "center",
        value: "totalTrips",
      },
      {
        text: "Hourly Rate",
        align: "center",
        value: "driver.hourly_rate",
      },
      {
        text: "Total Amount",
        align: "center",
        value: "totalAmmount",
      },
      {
        text: "Accidents",
        align: "center",
        value: "totalAccidents",
      },
      {
        text: "Fuel Consumption",
        align: "center",
        value: "totalFuel",
      },
      {
        text: "Fuel Amount",
        align: "center",
        value: "TotalDieselAmount",
      },
    ],
  }),
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
  async mounted() {
    var myCurrentDate = new Date();
    var myFutureDate = new Date(myCurrentDate);
    myCurrentDate.setDate(1);
    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);
    await this.fetch();

    this.intervalId = setInterval(() => {
      this.fetch();
    }, 10000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    focus() {
      if (this.selected_driver.id == -1) {
        this.search = "";
      } else {
        this.search = this.selected_driver.fullname;
      }
    },
    fetch() {
      this.loading = true;
      api
        .getdrivers(this.fdate, this.tdate)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.data = [];
            this.data = res.data.data;
            this.data.forEach((element) => {
              element.fullname =
                element.driver.fname + " " + element.driver.lname;

              element.TotalWorkingMinutes = element.TotalWorkingHours % 60;
              element.TotalWorkingHours = ~~Math.round(
                element.TotalWorkingHours / 60
              );

              element.totalAmmount = (
                element.TotalWorkingHours * element.driver.hourly_rate
              ).toFixed(2);

              element.totalFuel = element.totalLiters
                ? element.totalLiters.toFixed(2)
                : 0;
              element.totalTrips = element.totalTrips ? element.totalTrips : 0;

              element.TotalDieselAmount = element.TotalDieselAmount
                ? element.TotalDieselAmount.toFixed(2)
                : 0;

              this.driversData.push({
                fullname: element.fullname,
                id: element.driver.id,
              });
            });
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "success");
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });
    },
  },
};
