import api from "./api.js";
import settings from "@/plugins/settings.js";
import AddDriverDialog from "@/components/dialogs/add_driver_dialog.vue";

var driver_term = localStorage.getItem("driver_term") || "Driver";
var bus_term = localStorage.getItem("bus_term") || "Vehicle";
var currency = settings.fromNameToSymbol(
  localStorage.getItem("currency") || "Euro"
);
export default {
  components: {
    AddDriverDialog,
  },
  data: () => ({
    intervalId: null,
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    valid: false,
    errormessages: null,
    dialog: false,
    selectedObject: { id: -1 },
    tableIndex: -1,
    loading: true,
    deleteDialog: false,
    showBusCombobox: true,
    indexDriver: -1,
    newBus: {
      reference: null,
    },
    search: "",
    id: null,
    clicked: false,
    isrequired: true,
    driver_term: driver_term,
    bus_term: bus_term,
    currency: currency,
    json_fields: {
      Id: "id",
      "First Name": "fname",
      "Last Name": "lname",
      "E-mail": "email",
      [`${bus_term} Reference`]: "bus.reference",
      "Created Date": "created_date",
      "Hourly Rate": "hourly_rate",
    },
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 10) || "Password must be less than 10 characters",
      (v) => (v && v.length >= 4) || "Minimum 4 Characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    NormalRules: [(v) => !!v || "Field is required"],
    noRules: [],
    hourly_rate: 0.0,
    show1: false,
    show2: false,
    email: null,
    fname: null,
    lname: null,
    myCurrentData: [],
    p1: null,
    p2: null,
    ddata: [],
    headers: [
      { text: "First Name", align: "center", value: "fname" },
      { text: "Last Name", align: "center", value: "lname" },
      { text: "E-mail", align: "center", value: "email" },
      { text: `${bus_term} Reference`, align: "center", value: "bus" },
      {
        text: "Creation Date",
        align: "center",
        value: "created_date",
        filterable: false,
      },
      {
        text: "Hourly Rate",
        align: "center",
        value: "hourly_rate",
        filterable: false,
      },
      { text: "Actions", align: "center", value: "actions" },
    ],
  }),
  async mounted() {
    window.addEventListener("keyup", this.handleKeyPress);

    await this.fetch();

    this.intervalId = setInterval(() => {
      this.fetch();
    }, 10000);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyPress);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  watch: {
    p1: {
      handler(value) {
        if (value && value.toString().length > 0) {
          this.isrequired = true;
        } else {
          this.isrequired = false;
        }
      },
    },
    p2: {
      handler(value) {
        if (value && value.toString().length > 0) {
          this.isrequired = true;
        } else {
          this.isrequired = false;
        }
      },
    },
  },

  methods: {
    async fetch() {
      api
        .getdrivers()
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.ddata = [];
            
            let i = 0;
            res.data.data.forEach((element) => {
              this.ddata.push({ ...element, index: ++i });
            });
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });
    },

    handleKeyPress(event) {
      if (event.key === "Enter") {
        switch (true) {
          case this.dialog:
            this.save();
            return;
          case this.deleteDialog:
            this.agreeOnDelete();
            return;
          default:
            break;
        }
      }
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    showDialog(item) {
      this.selectedObject = item ? { ...item } : { id: -1 };
      this.indexDriver = item ? this.ddata.indexOf(item) : -1;
      this.dialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;
      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.deleteDialog = false;
            this.clicked = false;
            var index = this.ddata.indexOf(this.selectedObject);
            this.$delete(this.ddata, index);
            this.$snackbar.show(
              `${driver_term} deleted successfully.`,
              "success"
            );
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.errorDialog = true;
          this.deleteDialog = false;
          this.clicked = false;
        });
    },
    onDriverAdded(driver) {
      this.ddata.push({
        id: driver.id,
        fname: driver.fname,
        lname: driver.lname,
        email: driver.email,
        hourly_rate: driver.hourly_rate,
        created_date: driver.created_date,
        bus: driver.bus,
      });
    },
    onDriverUpdated(driver, index) {
      this.ddata[index].fname = driver.fname;
      this.ddata[index].lname = driver.lname;
      this.ddata[index].email = driver.email;
      this.ddata[index].hourly_rate = driver.hourly_rate;
      this.ddata[index].bus = driver.bus;
    },
  },
};
