<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.lay || "mainlayout";
    },
  },
};
</script>
