/* eslint-disable no-undef */
import { gmapApi } from 'vue2-google-maps';

export const tripColors = [
  '#FF5733',
  '#FF6F61',
  '#D72638',
  '#FF914D',
  '#FFB400',
  '#FFE156',
  '#FFC72C',
  '#E2B714',
  '#A8DADC',
  '#118AB2',
  '#073B4C',
  '#06D6A0',
  '#1B998B',
  '#2EC4B6',
  '#20A39E',
  '#64B6AC',
  '#8D99AE',
  '#6A0572',
  '#AB83A1',
  '#F72585',
  '#B5179E',
  '#7209B7',
  '#560BAD',
  '#480CA8',
  '#3A0CA3',
  '#3F37C9',
  '#4361EE',
  '#4895EF',
  '#4CC9F0',
  '#0EAD69',
  '#57CC99',
  '#80ED99',
  '#C7F9CC',
  '#A3A847',
  '#E9C46A',
  '#F4A261',
  '#E76F51',
  '#D00000',
  '#6A0572',
  '#9D4EDD',
  '#C77DFF',
  '#9C89B8',
  '#F0A6CA',
  '#FF99C8',
  '#FF686B',
  '#A4C3B2',
  '#E26D5C',
  '#723D46',
  '#472D30',
  '#3A405A',
  '#99B898',
  '#FECEAB',
  '#FF847C',
  '#E84A5F',
  '#2A363B',
  '#355C7D',
  '#6C5B7B',
  '#C06C84',
  '#F67280',
  '#F8B195',
  '#F6BD60',
  '#F7C59F',
  '#84A59D',
  '#F28482',
  '#F5CAC3',
  '#FF6700',
  '#FF4500',
  '#DB7093',
  '#800080',
  '#8A2BE2',
  '#9932CC',
  '#9400D3',
  '#4B0082',
  '#2E0854',
  '#191970',
  '#4682B4',
  '#00BFFF',
  '#7FFFD4',
  '#00FA9A',
  '#32CD32',
  '#7CFC00',
  '#ADFF2F',
  '#FFD700',
  '#FF8C00',
  '#FF4500',
  '#DC143C',
  '#8B0000',
  '#B22222',
  '#FA8072',
  '#FF6347',
  '#FFA07A',
  '#FFB6C1',
  '#FF69B4',
  '#FF1493',
  '#C71585',
  '#DB7093',
  '#FF69B4',
  '#DDA0DD',
  '#EE82EE',
  '#DA70D6',
];

export default class Direction {
  constructor(refs) {
    this.$refs = refs;
    this.directionsRenderers = [];
    this.directionColors = new Map();
  }

  get google() {
    return gmapApi;
  }

  async reset() {
    if (!this.directionsRenderers || !this.directionsRenderers.length) return;

    for (let dr of this.directionsRenderers) {
      dr.setMap(null);
      dr.setDirections({ routes: [] });
    }
    this.directionsRenderers = [];
  }

  async drawTripLine(origin, destination, waypoints = [], tripId) {
    const google = await this.google();
    if (!google) {
      console.error('Google Maps API is not loaded');
      return;
    }

    const directionsService = new google.maps.DirectionsService();
    const map = this.$refs.mapRef.$mapObject;

    if (!map) {
      console.error('Map reference is not set');
      return;
    }

    const sortedWaypoints = waypoints
      .map((wp) => (typeof wp === 'object' ? JSON.stringify(wp) : wp))
      .sort()
      .join('-');

    const key = `${tripId}-${origin}-${destination}-${sortedWaypoints}`;

    if (!this.directionColors.has(key)) {
      const colorIndex = this.directionColors.size % tripColors.length;
      this.directionColors.set(key, tripColors[colorIndex]);
    }

    const travelConfig = {
      origin,
      destination,
      waypoints,
      travelMode: google.maps.TravelMode.DRIVING,
      optimizeWaypoints: true,
    };

    directionsService.route(travelConfig, (response, status) => {
      if (status === 'OK' && response.routes && response.routes.length) {
        const color = this.directionColors.get(key);
        console.log(`Drawing route with color: ${color}`); // Debugging line

        const directionsRenderer = new google.maps.DirectionsRenderer({
          map,
          suppressMarkers: true,
          preserveViewport: true,
          polylineOptions: {
            strokeColor: color,
            strokeOpacity: 1,
            strokeWeight: 5,
          },
        });

        directionsRenderer.setDirections(response);
        this.directionsRenderers.push(directionsRenderer);
      } else {
        console.error('Directions request failed due to', status);
      }
    });
  }
}
