<template>
  <div class="form-wrapper">
    <div class="form-header">
      <div class="header-title">
        <p class="page-title">
          <v-icon fab class="main-page-icon" large>mdi-truck</v-icon>
          Train Your AI for Smarter Journey Management
        </p>
      </div>
    </div>
    <v-card class="form-card">
      <v-card-text class="form-content">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step class="v-stepper-step" step="1" @click="step = 1">Company Overview</v-stepper-step>
            <v-stepper-step class="v-stepper-step" step="2" @click="step = 2">Fleet & Operations</v-stepper-step>
            <v-stepper-step class="v-stepper-step" step="3" @click="step = 3">Industry & Competitors</v-stepper-step>
            <v-stepper-step class="v-stepper-step" step="4" @click="step = 4">Fleet Management & Challenges</v-stepper-step>
            <v-stepper-step class="v-stepper-step" step="5" @click="step = 5">Performance & Trip Scheduling</v-stepper-step>
            <v-stepper-step class="v-stepper-step" step="6" @click="step = 6">Additional Information</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form>
                <v-text-field label="Describe your company in one sentence" v-model="form.companyDescription"></v-text-field>
                <v-row>
                  <v-col>
                    <v-select
                      label="What products or services does your company offer?"
                      :items="services"
                      v-model="form.services"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.services.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherServices"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="What are your company’s core values?"
                      :items="coreValues"
                      v-model="form.coreValues"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.coreValues.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherCoreValues"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field label="Where are you primarily located? (Country & City)" v-model="form.location"></v-text-field>
                <v-row>
                  <v-col class="text-right">
                    <v-btn @click="nextStep">Next</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form>
                <v-row>
                  <v-col>
                    <v-select
                      label="What types of vehicles are in your fleet?"
                      :items="vehicleTypes"
                      v-model="form.vehicleTypes"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.vehicleTypes.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherVehicleTypes"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="What is the main goal of your trips?"
                      :items="tripGoals"
                      v-model="form.tripGoal"
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.tripGoal === 'Other'">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherTripGoal"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="What types of trips do you typically manage?"
                      :items="tripTypes"
                      v-model="form.tripTypes"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.tripTypes.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherTripTypes"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn @click="prevStep">Back</v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn @click="nextStep">Next</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-form>
                <v-row>
                  <v-col>
                    <v-select
                      label="What industries do you primarily serve?"
                      :items="industries"
                      v-model="form.industries"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.industries.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherIndustries"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field label="Who are your main competitors?" v-model="form.competitors"></v-text-field>
                <v-row>
                  <v-col>
                    <v-btn @click="prevStep">Back</v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn @click="nextStep">Next</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-form>
                <v-row>
                  <v-col>
                    <v-select
                      label="What are the biggest challenges in managing your fleet?"
                      :items="challenges"
                      v-model="form.challenges"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.challenges.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherChallenges"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="How do you currently handle vehicle maintenance and servicing?"
                      :items="maintenanceMethods"
                      v-model="form.maintenanceMethod"
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.maintenanceMethod === 'Other'">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherMaintenanceMethod"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="What safety measures do you have in place for your fleet?"
                      :items="safetyMeasures"
                      v-model="form.safetyMeasures"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.safetyMeasures.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherSafetyMeasures"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn @click="prevStep">Back</v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn @click="nextStep">Next</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-form>
                <v-row>
                  <v-col>
                    <v-select
                      label="What key metrics do you track for fleet performance?"
                      :items="performanceMetrics"
                      v-model="form.performanceMetrics"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.performanceMetrics.includes('Other')">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherPerformanceMetrics"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="How do you assign and schedule trips for your vehicles?"
                      :items="schedulingMethods"
                      v-model="form.schedulingMethod"
                    ></v-select>
                  </v-col>
                  <v-col v-if="form.schedulingMethod === 'Other'">
                    <v-text-field
                      label="If other, please specify"
                      v-model="form.otherSchedulingMethod"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn @click="prevStep">Back</v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn @click="nextStep">Next</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="6">
              <v-form>
                <v-textarea label="Do you have any additional comments or requirements for your fleet management?" v-model="form.additionalComments"></v-textarea>
                <v-row>
                  <v-col>
                    <v-btn @click="prevStep">Back</v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn @click="submitForm">Submit</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-alert v-if="formSubmitted" type="success" dismissible>
          Thank you for completing the onboarding form! 🚀
          Your responses will help us tailor our services to your needs.
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'FleetOnboardingForm',
  data() {
    return {
      step: 1,
      form: {
        companyDescription: '',
        services: [],
        otherServices: '',
        coreValues: [],
        otherCoreValues: '',
        location: '',
        vehicleTypes: [],
        otherVehicleTypes: '',
        tripGoal: '',
        otherTripGoal: '',
        tripTypes: [],
        otherTripTypes: '',
        industries: [],
        otherIndustries: '',
        competitors: '',
        challenges: [],
        otherChallenges: '',
        maintenanceMethod: '',
        otherMaintenanceMethod: '',
        safetyMeasures: [],
        otherSafetyMeasures: '',
        performanceMetrics: [],
        otherPerformanceMetrics: '',
        schedulingMethod: '',
        otherSchedulingMethod: '',
        additionalComments: '',
      },
      formSubmitted: false,
      services: [
        'Logistics & Delivery Services',
        'Passenger Transport',
        'Field Services (e.g., maintenance, repairs)',
        'Emergency Response',
        'Other',
      ],
      coreValues: [
        'Safety',
        'Sustainability',
        'Innovation',
        'Customer Satisfaction',
        'Efficiency',
        'Other',
      ],
      vehicleTypes: [
        'Cars',
        'Vans',
        'Trucks',
        'Motorcycles',
        'Bicycles',
        'Electric Vehicles',
        'Other',
      ],
      tripGoals: [
        'Delivering goods',
        'Transporting passengers',
        'Field service operations',
        'Other',
      ],
      tripTypes: [
        'Scheduled deliveries',
        'On-demand services',
        'Recurring service calls',
        'Long-haul transportation',
        'Other',
      ],
      industries: [
        'E-commerce',
        'Healthcare',
        'Manufacturing',
        'Retail',
        'Construction',
        'Government & Public Services',
        'Other',
      ],
      challenges: [
        'High operational costs',
        'Vehicle maintenance & downtime',
        'Route optimization',
        'Compliance & regulations',
        'Safety & accident prevention',
        'Other',
      ],
      maintenanceMethods: [
        'In-house mechanics',
        'Outsourced service providers',
        'Mix of both',
        'We do not have a structured maintenance plan',
        'Other',
      ],
      safetyMeasures: [
        'GPS tracking',
        'Driver training programs',
        'Speed monitoring',
        'Vehicle inspections',
        'Other',
      ],
      performanceMetrics: [
        'Fuel efficiency',
        'Delivery time & punctuality',
        'Vehicle downtime',
        'Driver behavior',
        'Other',
      ],
      schedulingMethods: [
        'Manually (via spreadsheets or calls)',
        'Using fleet management software',
        'Third-party logistics provider',
        'Other',
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    submitForm() {
      this.formSubmitted = true;
      console.log('Form submitted:', this.form);
    },
  },
};
</script>

<style scoped>
.form-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
}

.form-header {
  padding: 10px;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  display: flex;
  align-items: center;
}

.page-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.form-card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.v-stepper-header {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
}

.v-stepper-step {
  min-width: 120px;
  flex-shrink: 0;
  cursor: pointer;
  padding: 8px;
  font-size: 14px;
}

.v-stepper-content {
  padding: 20px;
}

.v-btn {
  margin-top: 20px;
}

@media (max-width: 1504px) {
  .form-wrapper {
    padding: 10px;
  }

  .form-card {
    width: 100%;
  }

  .v-stepper-header {
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: thin;
  }

  .v-stepper-step {
    min-width: 100px;
    font-size: 12px;
    padding: 6px;
  }
}

@media (max-width: 1355px) {
  .v-stepper-step {
    min-width: 80px;
    font-size: 12px;
    padding: 4px;
  }
}
</style>