import api from "./api.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";

export default {
  data: () => ({
    intervalId: null,
    valid: false,
    Email: "",
    totalMinutes: 0,
    sendDialog: false,
    sending: false,
    fileToSend: null,
    picked: "1",
    dialog: false,
    loading: true,
    text: "Please Select Driver",
    drivers: [],
    selected_driver: null,
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    show1: false,
    show2: false,
    myCurrentData: [],
    ddata: [],
    data: [],
    driver_term: driver_term,
    headers: [
      {
        text: "Date",
        align: "center",
        value: "Date",
      },
      {
        text: "Starting Date",
        align: "center",
        value: "StartingDate",
      },
      {
        text: "Ending Date",
        align: "center",
        value: "EndingDate",
      },
      {
        text: "Total Working Hours",
        align: "center",
        value: "TotalWorkingHours",
      },
    ],
  }),
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
  async mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    this.getFinance();
    var myCurrentDate = new Date();
    var myFutureDate = new Date(myCurrentDate);
    myCurrentDate.setDate(1);
    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);
    await this.fetch();

    this.intervalId = setInterval(() => {
      this.fetch();
    }, 10000);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyPress);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  watch: {
    fdate: {
      handler() {
        this.ddata = [];
        this.getSummary();
      },
    },
    tdate: {
      handler() {
        this.ddata = [];
        this.getSummary();
      },
    },
    selected_driver: {
      handler() {
        this.text = "Loading...Please wait";
        this.ddata = [];
        this.getSummary();
      },
    },
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if (this.sendDialog) this.sendFile();
      }
    },
    askSent() {
      this.sendDialog = true;
    },
    sendFile() {
      const emailField = this.$refs.emailField;

      if (emailField.validate()) {
        this.sending = true;
        this.sendDialog = false;

        let formData = new FormData();
        formData.append("file", JSON.stringify(this.ddata));
        formData.append("email", this.Email);
        formData.append("driver", this.selected_driver.fullname);
        formData.append("fdate", this.fdate);
        formData.append("tdate", this.tdate);
        formData.append("totalMinutes", this.totalMinutes);

        api
          .sendDriversSummary(formData)
          .then((res) => {
            let text;
            if (res.data.responseCode === 1) {
              text = "File sent successfully";
            } else {
              text = "Failed to send the file";
            }
            this.$snackbar.show(text, "success");
          })
          .catch(() => {
            this.$snackbar.show(
              "Error occurred while sending the file",
              "error"
            );
          })
          .finally(() => {
            this.sending = false;
          });
      }
    },

    getFinance() {
      api.getFinance().then((res) => {
        if (res.data.responseCode == 1) {
          this.Email = res.data.data;
        } else {
          this.Email = "";
        }
      });
    },
    getSummary() {
      this.loading = true;
      if (this.selected_driver) {
        api
          .getdriversSummary(this.fdate, this.tdate, this.selected_driver.id)
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.totalMinutes = 0;
              res.data.data.forEach((element) => {
                this.totalMinutes += ~~element.TotalWorkingHours;

                element.TotalWorkingMinutes = element.TotalWorkingHours % 60;
                element.TotalWorkingHours = ~~Math.round(
                  element.TotalWorkingHours / 60
                );
                // element.TotalWorkingMinutes = parseFloat(('0.' + (Math.round((element.TotalWorkingHours / 24) * 10) / 10).toFixed(2).toString().split(".").pop()) * 60);
                // element.TotalWorkingHours = ~~(Math.round((element.TotalWorkingHours / 24) * 10) / 10)
                let date = new Date(element.StartingDate);
                let text = date.toLocaleDateString("en-US", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });
                let d = {
                  Date: text,
                  EndingDate: element.EndingDate,
                  StartingDate: element.StartingDate,
                  TotalWorkingHours:
                    element.TotalWorkingHours +
                    " Hours " +
                    element.TotalWorkingMinutes +
                    " Minutes ",
                };
                if (element.EndingDate != null) this.ddata.push(d);
              });
            }
            this.loading = false;
          });
      }
    },
    fetch() {
      api.getdrivers().then((res) => {
        if (res.data.responseCode === 1) {
          this.drivers = [];
          
          res.data.data.forEach((element) => {
            let d = {
              id: element.id,
              fullname: element.fname + " " + element.lname,
            };
            this.drivers.push(d);
          });
        }
      });
    },
  },
};
