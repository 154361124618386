<template>
  <v-dialog v-model="dialog" max-width="600px" transition="dialog-bottom-transition" persistent>
    <v-card elevation="8" class="rounded-lg">
      <!-- Title + Close Icon -->
      <v-card-title class="d-flex align-center justify-space-between px-4 py-3">
        <span class="text-h5 font-weight-medium">Add Maintenance</span>
        <v-btn icon @click="closeDialog" size="small">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <!-- Form Content -->
      <v-card-text class="py-4 px-6">
        <v-form ref="form" v-model="valid" class="mt-3">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="maintenance.vehicle"
                :label="'Select ' + busTerm + ' *'"
                :items="vehicles"
                item-text="summary"
                item-value="id"
                :rules="[v => !!v || 'Please select a vehicle']"
                clearable
                dense
                outlined
                :loading="loadingVehicles"
                :search-input="searchQuery"
                @update:search-input="(val) => {
                      searchQuery = val;
                      fetchVehicles(val);
                    }
                "
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="maintenance.type"
                label="Maintenance Type *"
                :items="maintenanceTypes"
                :rules="[v => !!v || 'Please select a maintenance type']"
                clearable
                dense
                outlined
                :loading="loadingMaintenanceTypes"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="maintenance.date"
                label="Maintenance Date *"
                type="date"
                :rules="[v => !!v || 'Please enter a maintenance date']"
                required
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="maintenance.completedBy"
                label="Completed By"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="maintenance.cost"
                type="text"
                label="Cost"
                :prepend-inner-icon="currency"
                :rules="[v => !v || validatePrice(v) || 'Enter a valid price (e.g., 10, 10.5, 10.99)']"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea
                v-model="maintenance.note"
                label="Note"
                auto-grow
                dense
                outlined
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <!-- Actions -->
      <v-card-actions class="px-6 py-4 d-flex justify-end">
        <v-btn color="red darken-1" text @click="closeDialog">Cancel</v-btn>
        <v-btn color="green darken-1" :disabled="!valid" @click="submitMaintenance">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/http.service.js";

export default {
  name: "AddMaintenanceDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    busTerm: {
      type: String,
      default: "Vehicle",
    },
    currency: {
      type: String,
      default: "$",
    },
  },

  data() {
    return {
      valid: false,
      maintenance: {
        vehicle: null,
        date: "",
        note: "",
        type: "",
        completedBy: "",
        cost: "",
      },
      vehicles: [],
      maintenanceTypes: [],
      searchQuery: "",
      searchTimeout: null,
      loadingVehicles: false,
      loadingMaintenanceTypes: false,
    };
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.fetchVehicles();
        this.fetchMaintenanceTypes();
      } else {
        this.resetForm();
      }
    },
  },


  methods: {
    /* ---------------------------------------------------------------------
     *                        FORM SUBMISSION
     * --------------------------------------------------------------------- */
    async submitMaintenance() {
      if (!this.$refs.form.validate()) return;

      try {
        await axios.post("v1/vehicles/maintenances", {
          bus_id: this.maintenance.vehicle?.id,
          date: this.maintenance.date,
          note: this.maintenance.note,
          type: this.maintenance.type,
          cost: this.maintenance.cost,
          completed_by: this.maintenance.completedBy,
        });

        this.$emit("maintenance-added", this.maintenance);
        this.closeDialog();
      } catch (error) {
        console.error("Failed to submit maintenance:", error);
        this.$snackbar.show("Failed to submit maintenance", "error");
      }
    },

    /* ---------------------------------------------------------------------
     *                           DATA FETCHING
     * --------------------------------------------------------------------- */
    async fetchVehicles(reference = "") {
      this.loadingVehicles = true;
      try {
        const res = await axios.get(`bus/get-buses?limit=4&reference=${reference}`);
        if (res.data.responseCode) {
          this.vehicles = res.data.data.map((element) => ({
            ...element,
            summary: `${element.reference}: ${element.brand} ${element.model}`,
          }));
        }
      } catch (error) {
        console.error("API error (fetchVehicles):", error);
      }
      this.loadingVehicles = false;
    },

    async fetchMaintenanceTypes() {
      this.loadingMaintenanceTypes = true;
      try {
        const res = await axios.get("v1/vehicles/maintenances/types");
        if (res.data.success) {
          this.maintenanceTypes = res.data.data;
        }
      } catch (error) {
        console.error("API error (fetchMaintenanceTypes):", error);
      }
      this.loadingMaintenanceTypes = false;
    },


    /* ---------------------------------------------------------------------
     *                           VALIDATIONS
     * --------------------------------------------------------------------- */
    validatePrice(value) {
      if (!value) return true; 
      
      if (isNaN(value)) return 'Please enter a valid number'; 
      
      const regex = /^\d+(\.\d{1,2})?$/; 
      return regex.test(value) || 'Enter a valid price (e.g., 10, 10.5, 10.99)';
    },


    /* ---------------------------------------------------------------------
     *                           UTILITY
     * --------------------------------------------------------------------- */
    closeDialog() {
      this.$emit("close-dialog");
    },
    resetForm() {
      this.valid = false;
      this.maintenance = {
        vehicle: null,
        date: "",
        note: "",
        type: "",
        completedBy: "",
        cost: "",
      };
      this.$refs.form.resetValidation();
    },
  },
};
</script>

