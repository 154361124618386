import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrappperheader"},[_c('div',{staticClass:"headertitle"},[_c('p',{staticClass:"pagetitle"},[_c(VIcon,{staticClass:"mainpageicon",attrs:{"fab":"","large":""}},[_vm._v(_vm._s(this.$route.meta.mainicon))]),_vm._v(" "+_vm._s(this.$route.meta.title)+" ")],1)]),_c('div',{staticClass:"headerbuttons"},[_c('div',{staticClass:"btn"},[_c(VBtn,{on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Add ")])],1)])]),_c(VCard,[_c(VDataTable,{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.data,"sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"ma-2",attrs:{"color":"primary","variant":"text","small":""},on:{"click":function($event){return _vm.viewHistory(item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-history")]),_vm._v(" View Full History ")],1),_c(VBtn,{staticClass:"ma-2",attrs:{"color":"secondary","variant":"text","small":""},on:{"click":function($event){return _vm.rescheduleMaintenance(item)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-calendar-clock")]),_vm._v(" Reschedule Maintenance ")],1)]}}],null,true)},[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1),_c('add-maintenance-dialog',{attrs:{"dialog":_vm.dialog,"busTerm":_vm.bus_term,"currency":_vm.currency},on:{"maintenance-added":_vm.onMaintenanceAdded,"close-dialog":function($event){_vm.dialog = false}}}),_c('view-maintenance-history-dialog',{attrs:{"dialog":_vm.historyDialog,"history":_vm.history},on:{"close-dialog":_vm.closeHistoryDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }