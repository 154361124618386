import router from "../router/index.js";
import api from "./api.js";
import axios from "@/plugins/http.service.js";
import settings from "../plugins/settings.js";

var currency = settings.fromNameToSymbol(
  localStorage.getItem("currency") || "Euro"
);


var driver_term = localStorage.getItem('driver_term') || 'Driver';
var trip_term = localStorage.getItem('trip_term') || 'Trip';
var destination_term = localStorage.getItem('destination_term') || 'Destination';
var warehouse_term = localStorage.getItem('warehouse_term') || 'Origin';
var bus_term = localStorage.getItem('bus_term') || 'Vehicle';

export default {
  name: "mainlayout",

  data() {
    return {
      script_element: null,
      first_message: "",
      email: sessionStorage.getItem("cooriroo_admin_email"),
      tenant_name: sessionStorage.getItem("tenant_name") ?? null,
      tenant_type: sessionStorage.getItem("tenant_type") ?? null,
      exception_type: new Map([
        ["started_late", [trip_term + " started late", "mdi-clock-alert"]],
        ["started_soon", [trip_term + " started soon", "mdi-clock-alert"]],
        [
          "missed_destination",
          ["Missed " + destination_term, "mdi-map-marker-off"],
        ],
        [
          "driving_time_out_of_range",
          ["Driving time out of range", "mdi-bus-clock"],
        ],
        ["idle_location", ["Idle Location", "mdi-pause-octagon"]],
      ]),
      drawer: true,
      enterTimeout: null,
      leaveTimeout: null,
      mini: true,
      toright: false,
      mobileView: false,
      dropDownMenu: false,
      exceptions: [],
      notSeenExceptionsCounter: 0,
      intervalId: null,
      fullname: "Admin",
      loggedin: false,
      limit: 4, // limit seen exceptions
      items: [],
      firststyle: "color:red",
      secondstyle: "transform: scaleX(-1);filter: FlipH; ;",
      minWidth: 80,
      currency: currency,
      trip_term: trip_term,
      driver_term: driver_term,
      destination_term: destination_term,
      warehouse_term: warehouse_term,
      bus_term: bus_term,

      snackbar: {
        visible: false,
        message: "",
        color: "error",
      },
    };
  },

  async mounted() {
    this.onResize(null);
    const isSuperAdmin = sessionStorage.getItem("is_super_admin") == 1;
    this.loggedin = true;
    if (this.tenant_type == "school_bus")
      this.items = [
        { title: 'Dashboard', icon: 'dashboard', to: '/dashboard' },
        { title: `${trip_term}s`, icon: 'loyalty', to: '/trips' },
        { title: 'Vehicles', icon: 'directions_bus', to: '/vehicles' },
        { title: 'Maintenance', icon: 'mdi-wrench-clock', to: '/maintenance' },

        { title: `${bus_term}s Analytics`, icon: 'mdi-poll', to: '/vehicles-analytics' },

        { title: 'Students', icon: 'mdi-school', to: '/students' },
        { title: `${driver_term}s`, icon: 'person_search', to: '/drivers' },
        {
          title: `Scheduled ${trip_term}s`,
          icon: "timer",
          to: "/scheduled-trips",
        },
        {
          title: "Reports",
          icon: "insert_chart",
          children: [
            {
              title: `${driver_term} Summary`,
              icon: "description",
              to: "/driver-summary",
            },
            {
              title: "Finance Summary",
              icon: "mdi-currency-usd",
              to: "/finance-summary",
            },
            { title: "Logs", icon: "source", to: "/logs" },
          ],
        },
        { title: "Exceptions", icon: "subject", to: "/exceptions" },
        {
          title: "Change Vehicle Requests",
          icon: "sync_alt",
          to: "/change-vehicle-requests",
        },
        { title: "Admins", icon: "admin_panel_settings", to: "/admins" },
        {
          title: "Push Notifications",
          icon: "notifications_none",
          to: "/push-notifications",
        },
        { title: "Refuel", icon: "local_gas_station", to: "/refuel" },
        { title: "Accidents", icon: "bus_alert", to: "/accidents" },
        { title: "Fines", icon: "request_quote", to: "/fines" },
        { title: "Analytics", icon: "mdi-google-analytics", to: "/analytics" },
      ];
    else if (isSuperAdmin) {
      this.items = [
        {
          title: "Super Admins",
          icon: "admin_panel_settings",
          to: "/super-admins",
        },
      ];
    } else
      this.items = [
        { title: 'Dashboard', icon: 'dashboard', to: '/dashboard' },
        { title: `${trip_term}s`, icon: 'loyalty', to: '/trips' },
        { title: 'Vehicles', icon: 'directions_bus', to: '/vehicles' },
        { title: 'Maintenance', icon: 'mdi-wrench-clock', to: '/maintenance' },
        { title: `${bus_term}s Analytics`, icon: 'mdi-poll', to: '/vehicles-analytics' },
        { title: `${driver_term}s`, icon: 'person_search', to: '/drivers' },
        { title: `${warehouse_term}s`, icon: 'apartment', to: '/origins' },
        { title: `${destination_term}s`, icon: 'place', to: '/destinations' },
        {
          title: `Scheduled ${trip_term}s`,
          icon: "timer",
          to: "/scheduled-trips",
        },
        {
          title: `${trip_term} Types`,
          icon: "format_list_bulleted",
          to: "/trip-types",
        },
        { title: "Refuel", icon: "local_gas_station", to: "/refuel" },
        { title: "AI", icon: "mdi-robot-outline", to: "/ai" },
        { title: "Accidents", icon: "bus_alert", to: "/accidents" },
        { title: "Fines", icon: "request_quote", to: "/fines" },
        { title: "Analytics", icon: "mdi-google-analytics", to: "/analytics" },
        {
          title: "Reports",
          icon: "insert_chart",
          children: [
            {
              title: `${driver_term} Summary`,
              icon: "description",
              to: "/driver-summary",
            },
            {
              title: "Finance Summary",
              icon: "mdi-currency-usd",
              to: "/finance-summary",
            },
            { title: "Logs", icon: "source", to: "/logs" },
          ],
        },
        {
          title: "Change Vehicle Requests",
          icon: "sync_alt",
          to: "/change-vehicle-requests",
        },
        {
          title: "Approvals",
          icon: "mdi-file-document-edit-outline",
          to: "/approvals",
        },
        { title: "Exceptions", icon: "subject", to: "/exceptions" },
        { title: "API", icon: "mdi-api", to: "/api-catalog" },
        { title: "Admins", icon: "admin_panel_settings", to: "/admins" },
        {
          title: "Push Notifications",
          icon: "notifications_none",
          to: "/push-notifications",
        },
      ];
    if (!isSuperAdmin) {
      this.intervalId = setInterval(() => {
        this.getExceptions();
      }, 10000);
    }

    await api
      .startChatSession()
      .then((res) => {
        if (res.data.success) {
          this.first_message = res.data.message;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    const options = {
      token: "",
      apiUrl: "https://api.test.cooriroo.com",
      apiKey: process.env.VUE_APP_OPENAI_API_KEY,
      initialMessage:
        this.first_message || "Hello, how can I assist you today?",
    };

    console.log(options);

    this.script_element = document.createElement("script");
    this.script_element.src =
      "https://unpkg.com/@jad.mahmoud/cooriroo-agent@latest/dist-embed/pilot.js";
    this.script_element.type = "module";

    this.script_element.onload = () => {
      if (typeof window.initAiCoPilot === "function") {
        window.initAiCoPilot(options);
      } else {
        console.error("initAiCoPilot is not defined even after script load.");
      }
    };

    this.script_element.onerror = () => {
      console.error("Failed to load the OpenCX widget script.");
    };

    document.head.appendChild(this.script_element);
  },

  created() {
    document.addEventListener("resize", this.onResize);
  },

  destroyed() {
    document.removeEventListener("resize", this.onResize);
  },

  async beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.script_element) {
      document.head.removeChild(this.script_element);
      this.script_element = null;
    }

    clearTimeout(this.enterTimeout);
    clearTimeout(this.leaveTimeout);

    // Remove the UI elements injected by the script
    const widget_roots = ["#opencopilot-root", "#devchatbot-root"];
    widget_roots.forEach((selector) => {
      const element = document.querySelector(selector);
      if (element) {
        console.log(`Removing widget: ${selector}`);
        element.remove();
      }
    });

    // Clean up any additional global variables if necessary
    if (typeof window.initAiCoPilot !== "undefined") {
      delete window.initAiCoPilot;
    }

    await api
      .closeChatSession()
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  },

  computed: {
    tenantName: function () {
      if (this.tenant_type === "namat") {
        return "";
      }
      return this.tenant_name
        ? "Cooriroo Adminstration - " + this.tenant_name
        : "";
    },
    logo: function () {
      var images = require.context("../assets/images", false);
      if (this.tenant_type === "namat") {
        return images("./namat.png");
      }
      return images("./logo.svg");
    },
    barStyle: function () {
      if (this.tenant_type === "namat") {
        return "border-bottom: 2px solid #ec4962; background: white; z-index:4444;";
      }
      return "border-bottom: 2px solid #fbc853; background: white; z-index:4444;";
    },
    mainStyle() {
      return {
        position: "relative",
        paddingLeft: this.drawer ? "0px" : this.minWidth + "px",
      };
    },
  },

  methods: {
    handleMouseEnter() {
      clearTimeout(this.leaveTimeout);
      this.enterTimeout = setTimeout(() => {
        this.mini = false;
      }, 1);
    },
    handleMouseLeave() {
      clearTimeout(this.enterTimeout);
      this.leaveTimeout = setTimeout(() => {
        this.mini = true;
      }, 300);
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }
    },

    parseDate(dateString) {
      const [datePart, timePart] = dateString.split(", ");
      const [day, month, year] = datePart.split("/").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);

      return new Date(year, month - 1, day, hours, minutes, seconds);
    },

    parseAndConvert(dateString) {
      return this.convertUTCDateToLocalDate(this.parseDate(dateString));
    },

    changeBar() {
      if (this.mini != false) {
        this.mini = !this.mini;
      }
    },

    getExceptions() {
      api
        .getNotSeenExceptions()
        .then((response) => {
          if (response.data.success) {
            this.exceptions = response.data.data.map((exception) => ({
              id: exception.id,
              text:
                this.exception_type.get(exception.type)?.[0] || exception.type,
              driver_name: exception.driver.fname,
              driver_surname: exception.driver.lname,
              departed_from: exception.trip.warehouse.title,
              time_exception: this.convertUTCDateToLocalDate(
                exception.creation_date
              ),
              seen: exception.seen,
              icon: this.exception_type.get(exception.type)?.[1] || null,
            }));

            this.notSeenExceptionsCounter = this.exceptions.length;
            if (this.notSeenExceptionsCounter > 0) {
              this.setNewIcon();
            } else {
              this.resetIcon();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setNewIcon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = "/favicon_notification.svg";
    },

    resetIcon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = "/favicon.svg";
    },

    markAllAsRead() {
      this.resetIcon();
      this.notSeenExceptionsCounter = 0;
      this.exceptions.forEach((exception) => {
        exception.seen = true;
      });
      this.dropDownMenu = false;
      api.readAllExceptions();
    },

    redirect(exceptionId) {
      if (sessionStorage.getItem("cooriroo_admin_id") !== null) {
        sessionStorage.setItem("cooriroo_exception_id", exceptionId);

        if (router.currentRoute.name !== "Exceptions") {
          router.push("/exceptions");
        }

        api.readException(exceptionId);

        const exception = this.exceptions.find((x) => x.id === exceptionId);
        if (exception) {
          if (!exception.seen) {
            exception.seen = true;

            this.notSeenExceptionsCounter--;

            if (this.notSeenExceptionsCounter === 0) {
              this.resetIcon();
            }
          }
        }

        this.dropDownMenu = false;
      } else {
        router.push("/");
      }
    },

    logout: function () {
      sessionStorage.removeItem("cooriroo_admin_id");
      sessionStorage.removeItem("cooriroo_tenant_id");
      sessionStorage.removeItem("cooriroo_admin_email");
      sessionStorage.removeItem("is_super_admin");
      sessionStorage.removeItem("tenant_name");
      sessionStorage.removeItem("tenant_type");
      sessionStorage.removeItem("cooriroo_exception_id");

      localStorage.clear();

      this.$router.push("/");

      axios
        .post("admin/logout")
        .then(() => {
          console.log("Logout successfull.");
        })
        .catch((error) => {
          console.error("error during logout:", error);
        });
    },

    onResize() {
      this.minWidth = "80";
      if (window.innerWidth >= 1024) {
        this.mobileView = false;
      } else {
        this.mobileView = true;
      }
    },

    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.visible = true;
    },
  },
};
