<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">
            {{ title }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="localType.title"
                  :rules="normalRules"
                  label="Title"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="localType.time_stop"
                  label="Unauthorized stop time"
                  clearable
                  hide-details="auto"
                  type="text"
                  suffix="minutes"
                  pattern="[0-9]*"
                  @keypress="onlyDigits"
                >
                  <template v-slot:append>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"
                          >mdi-help-circle-outline</v-icon
                        >
                      </template>
                      <span>Maximum time for an unauthorized stop</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="closeDialog">Close</v-btn>
          <v-btn
            text
            color="primary"
            :disabled="clicked"
            @click="index === -1 ? saveType() : updateType()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/http.service.js";

export default {
  name: "AddTypeDialog",
  props: {
    title: {
      type: String,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Object,
      default: () => ({
        id: -1,
        title: "",
        time_stop: null,
      }),
    },
    index: {
      type: Number,
      default: -1,
    },
    tripTerm: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
      clicked: false,
      localType: { ...this.type },
      normalRules: [
        (v) => !!v || "Field is required",
        (v) => v.length <= 50 || "Title must be less than 50 characters",
      ],
      numberRules: [
        (v) =>
          v === null ||
          v === "" ||
          (v > 0 && v <= 1440) ||
          "Enter a valid number (1-1440)",
      ],
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
        this.localType = { ...this.type };
      } else {
        this.localType = { id: -1, title: "", time_stop: null };
      }
    },
  },
  methods: {
    onlyDigits(e) {
      const char = String.fromCharCode(e.keyCode);
      if (!/^[0-9]$/.test(char)) {
        e.preventDefault();
      }
    },

    closeDialog() {
      this.$emit("close-dialog");
      this.localType = { id: -1, title: "", time_stop: null };
    },
    async saveType() {
      if (!this.$refs.form.validate()) {
        this.$snackbar.show("Please fill the form correctly.", "error");
        return;
      }
      this.clicked = true;
      try {
        const response = await axios.post("type/add", this.localType);
        if (response.data.responseCode === 1) {
          this.$emit("type-added", response.data.data);
          this.$snackbar.show("Type added successfully.", "success");
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage || "Failed to add type.",
            "error"
          );
        }
      } catch (error) {
        this.$snackbar.show(`Error adding type: ${error.message}`, "error");
      } finally {
        this.clicked = false;
      }
    },
    async updateType() {
      if (!this.$refs.form.validate()) {
        this.$snackbar.show("Please fill the form correctly.", "error");
        return;
      }
      this.clicked = true;
      try {
        const response = await axios.post("type/save", this.localType);
        if (response.data.responseCode === 1) {
          this.$emit("type-updated", response.data.data, this.index);
          this.$snackbar.show("Type updated successfully.", "success");
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage || "Failed to update type.",
            "error"
          );
        }
      } catch (error) {
        this.$snackbar.show(`Error updating type: ${error.message}`, "error");
      } finally {
        this.clicked = false;
      }
    },
  },
};
</script>

<style scoped>
/* Aggiungi qui eventuali stili specifici per la dialog */
</style>
