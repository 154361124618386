// script.js
import settings from "../../plugins/settings.js";
import api from "./api.js";

export default {
  data() {
    return {
      // Initialize activeTab to 0 (Configuration) by default
      activeTab: 0, 

      loading: false,
      snackbar: false,
      text: '',
      spaceUnits: settings.getSpaceUnits() || ['Meters', 'Kilometers', 'Miles', 'Feet'],
      liquidUnits: settings.getLiquidUnits() || ['Liters', 'Gallons', 'Cubic Meters'],
      currencies: settings.getCurrencyNames() || ['USD', 'EUR', 'GBP', 'JPY'],

      NumberRules: [
        (v) => v === null || v === undefined || v === "" || v >= 0 || "Please enter a non-negative number",
      ],
      localSettings: {
        ...settings.getDefaultSettings(),
      },
    };
  },
  
  mounted() {    
    this.getSettings();
    console.log(this.localSettings);
  },
  
  methods: {
    async getSettings() {
      try {
        const res = await api.getSettings();
        if (res.data.responseCode === 1) {
          res.data.data.forEach((setting) => {
            this.$set(this.localSettings, setting.key, setting.value);
          });
        } else {
          console.error('Failed to fetch settings:', res.data.message);
          this.text = 'Failed to fetch settings';
          this.snackbar = true;
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
        this.text = 'Error fetching settings';
        this.snackbar = true;
      }
    },

    async saveSettings() {
      this.loading = true;
      const settingsArray = Object.entries(this.localSettings).map(([key, value]) => ({
        key,
        value,
      }));


      try {
        console.log(settingsArray);
        const res = await api.save_all_settings(settingsArray);
        if (res.data.responseCode === 1) {
          this.text = "Settings updated successfully";
          settings.setSettings(settingsArray);
          window.location.reload();
        } else {
          this.text = "Failed to update settings";
        }
      } catch (error) {
        this.text = "An error occurred while updating settings";
        console.error('Error saving settings:', error);
      } finally {
        this.snackbar = true;
        this.loading = false;
      }
    },

    resetSettings() {
      this.getSettings();
      this.text = 'Settings have been reset.';
      this.snackbar = true;
    },

    onPlaceSelected(place) {
      if (place && place.geometry && place.geometry.location) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        this.$set(this.localSettings, 'lat', String(latitude));
        this.$set(this.localSettings, 'lon', String(longitude));
        this.$set(this.localSettings, 'location', place.formatted_address || '');
      } else {
        console.error('Invalid place selected:', place);
        this.text = 'Invalid place selected';
        this.snackbar = true;
      }
    },

    // Method to handle input changes for the location field
    updateLocation(value) {
      this.localSettings.location.title = value;
    },
  },
};
