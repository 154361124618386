import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"rules":_vm.normalRules,"label":"Title","required":""},model:{value:(_vm.localType.title),callback:function ($$v) {_vm.$set(_vm.localType, "title", $$v)},expression:"localType.title"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Unauthorized stop time","clearable":"","hide-details":"auto","type":"text","suffix":"minutes","pattern":"[0-9]*"},on:{"keypress":_vm.onlyDigits},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Maximum time for an unauthorized stop")])])]},proxy:true}]),model:{value:(_vm.localType.time_stop),callback:function ($$v) {_vm.$set(_vm.localType, "time_stop", $$v)},expression:"localType.time_stop"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":_vm.closeDialog}},[_vm._v("Close")]),_c(VBtn,{attrs:{"text":"","color":"primary","disabled":_vm.clicked},on:{"click":function($event){_vm.index === -1 ? _vm.saveType() : _vm.updateType()}}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }