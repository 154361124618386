import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"driverForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"First Name","rules":_vm.normalRules,"required":""},model:{value:(_vm.localDriver.fname),callback:function ($$v) {_vm.$set(_vm.localDriver, "fname", $$v)},expression:"localDriver.fname"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"label":"Last Name","rules":_vm.normalRules,"required":""},model:{value:(_vm.localDriver.lname),callback:function ($$v) {_vm.$set(_vm.localDriver, "lname", $$v)},expression:"localDriver.lname"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Email","rules":_vm.emailRules,"required":""},model:{value:(_vm.localDriver.email),callback:function ($$v) {_vm.$set(_vm.localDriver, "email", $$v)},expression:"localDriver.email"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Password","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":_vm.index === -1
                    ? _vm.passwordRules
                    : [function (v) { return !v || v.length >= 4 || 'Minimum 4 characters'; }],"required":_vm.index == -1},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.localDriver.password),callback:function ($$v) {_vm.$set(_vm.localDriver, "password", $$v)},expression:"localDriver.password"}})],1),_c(VCol,{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"9"}},[_c(VCombobox,{attrs:{"clearable":"","dense":"","item-value":"id","item-text":"reference","search-input":_vm.searchTermBus,"items":_vm.buses,"menu-props":"auto","label":_vm.busTerm + ' Reference',"hide-details":"","prepend-icon":"directions_bus","single-line":"","return-object":"","filter":function () { return true; }},on:{"change":_vm.handleBusChange,"update:search-input":function (val) {
                    _vm.searchTermBus = val;
                    _vm.refreshBuses(null, val);
                  }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c(VListItem,_vm._g(_vm._b({class:{ 'add-item': data.item.id === -1 }},'v-list-item',data.attrs,false),data.on),[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(data.item.reference)+" ")])],1)],1)]}}]),model:{value:(_vm.localDriver.bus),callback:function ($$v) {_vm.$set(_vm.localDriver, "bus", $$v)},expression:"localDriver.bus"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"type":"number","label":"Hourly Rate","prepend-inner-icon":_vm.currency,"min":"0.00"},model:{value:(_vm.localDriver.hourly_rate),callback:function ($$v) {_vm.$set(_vm.localDriver, "hourly_rate", $$v)},expression:"localDriver.hourly_rate"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCombobox,{attrs:{"clearable":"","items":_vm.languages,"menu-props":"auto","label":"Language","hide-details":"","prepend-icon":"language","single-line":"","return-object":""},model:{value:(_vm.localDriver.language),callback:function ($$v) {_vm.$set(_vm.localDriver, "language", $$v)},expression:"localDriver.language"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v("Close")]),_c(VBtn,{staticClass:"btn",attrs:{"text":""},on:{"click":function($event){_vm.index === -1 ? _vm.saveDriver() : _vm.updateDriver()}}},[_vm._v(" Save ")])],1)],1)],1),_c('add-bus-dialog',{attrs:{"bus-term":_vm.busTerm,"dialog":_vm.addBusDialog,"title":'Add ' + _vm.busTerm},on:{"close-bus-dialog":_vm.closeAddBusDialog,"bus-added":_vm.onBusAdded}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }