import axios from '@/plugins/http.service.js';

export default {
  getExceptions() {
    return axios.get('v1/exceptions');
  },

  startChatSession() {
    return axios.post('v1/chat-bot', { message: '', first_message: true });
  },

  closeChatSession() {
    return axios.patch('v1/chat-bot/close');
  },

  getNotSeenExceptions(page = 1, pageSize = 10) {
    return axios.get(
      `v1/exceptions/not_seen?page=${page}&page_size=${pageSize}`
    );
  },

  getException(id) {
    return axios.get(`v1/exceptions/${id}`);
  },

  readException(id) {
    return axios.post(`/v1/exceptions/${id}/seen`);
  },

  readAllExceptions() {
    return axios.post(`/v1/exceptions/seen`);
  },

  /*getNotSeenExceptions() {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              data: {
                success: true,
                data: [
                  {
                    id: 336,
                    driver_id: 26,
                    trip_id: 374,
                    tenant_id: 1,
                    type: "missed_destination",
                    data: "Speeding up to avoid traffic",
                    creation_date: "2024-09-26T15:07:53Z",
                    seen: false,
                    driver: {
                      id: 26,
                      fname: "Alex",
                      lname: "Johnson",
                      email: "alex.johnson@example.com",
                      bus_id: 20,
                      tenant_id: 1,
                      hourly_rate: 20,
                      created_date: "2024-05-30T10:51:09Z",
                      archived: 0,
                      language: "en",
                      trips: null,
                      locations: null
                    },
                    trip: {
                      id: 374,
                      driver_id: 26,
                      warehouse_id: 1,
                      type: 19,
                      destination_id: null,
                      flag: 1,
                      created_date: "2024-09-26T15:07:29Z",
                      started_date: "2024-09-26T15:07:53Z",
                      ended_date: null,
                      start_date: "2024-09-26T19:07:00Z",
                      note: "Start trip early",
                      archived: 0,
                      trip_type: "Regular",
                      warehouse: {
                        id: 1,
                        title: "Berlin Warehouse",
                        lat: null,
                        lon: null,
                        created_date: "2024-05-08T09:21:14Z",
                        archived: 0,
                        tenant_id: 1
                      },
                      destinations: [
                        {
                          id: 200,
                          trip_id: 374,
                          destination_name: "Berlin Central Station",
                          destination_lat: 52.52437,
                          destination_lon: 13.41053,
                          task: "Pickup goods",
                          client_approval: false,
                          manager_approval: false,
                        }
                      ]
                    }
                  },
                  {
                    id: 337,
                    driver_id: 27,
                    trip_id: 375,
                    tenant_id: 1,
                    type: "driving_time_out_of_range",
                    data: "Delayed due to maintenance",
                    creation_date: "2024-09-25T08:30:53Z",
                    seen: false,
                    driver: {
                      id: 27,
                      fname: "Emily",
                      lname: "Smith",
                      email: "emily.smith@example.com",
                      bus_id: 21,
                      tenant_id: 1,
                      hourly_rate: 22,
                      created_date: "2024-05-20T10:20:09Z",
                      archived: 0,
                      language: "en",
                      trips: null,
                      locations: null
                    },
                    trip: {
                      id: 375,
                      driver_id: 27,
                      warehouse_id: 2,
                      type: 20,
                      destination_id: null,
                      flag: 1,
                      created_date: "2024-09-25T08:00:00Z",
                      started_date: "2024-09-25T08:30:53Z",
                      ended_date: null,
                      start_date: "2024-09-25T08:15:00Z",
                      note: "Start trip late",
                      archived: 0,
                      trip_type: "Urgent",
                      warehouse: {
                        id: 2,
                        title: "Hamburg Warehouse",
                        lat: null,
                        lon: null,
                        created_date: "2024-05-10T09:21:14Z",
                        archived: 0,
                        tenant_id: 1
                      },
                      destinations: [
                        {
                          id: 201,
                          trip_id: 375,
                          destination_name: "Hamburg Port",
                          destination_lat: 53.54639,
                          destination_lon: 9.98597,
                          task: "Deliver goods",
                          client_approval: false,
                          manager_approval: false,
                        }
                      ]
                    }
                  },
                  {
                    id: 338,
                    driver_id: 28,
                    trip_id: 376,
                    tenant_id: 1,
                    type: "started_soon",
                    data: "Avoiding heavy traffic",
                    creation_date: "2024-09-24T11:45:00Z",
                    seen: false,
                    driver: {
                      id: 28,
                      fname: "John",
                      lname: "Doe",
                      email: "john.doe@example.com",
                      bus_id: 22,
                      tenant_id: 1,
                      hourly_rate: 25,
                      created_date: "2024-05-15T14:30:10Z",
                      archived: 0,
                      language: "en",
                      trips: null,
                      locations: null
                    },
                    trip: {
                      id: 376,
                      driver_id: 28,
                      warehouse_id: 3,
                      type: 21,
                      destination_id: null,
                      flag: 1,
                      created_date: "2024-09-24T11:30:00Z",
                      started_date: "2024-09-24T11:45:00Z",
                      ended_date: null,
                      start_date: "2024-09-24T12:00:00Z",
                      note: "Started early to avoid traffic",
                      archived: 0,
                      trip_type: "Standard",
                      warehouse: {
                        id: 3,
                        title: "Munich Warehouse",
                        lat: null,
                        lon: null,
                        created_date: "2024-05-12T11:00:00Z",
                        archived: 0,
                        tenant_id: 1
                      },
                      destinations: [
                        {
                          id: 202,
                          trip_id: 376,
                          destination_name: "Munich Airport",
                          destination_lat: 48.3538,
                          destination_lon: 11.7861,
                          task: "Airport drop-off",
                          client_approval: false,
                          manager_approval: false,
                        },{
                          id: 202,
                          trip_id: 376,
                          destination_name: "Munich Airport",
                          destination_lat: 48.3538,
                          destination_lon: 11.7861,
                          task: "Airport drop-off",
                          client_approval: false,
                          manager_approval: false,
                        }
                      ]
                    }
                  }
                ],
              },
              message: "Exceptions retrieved successfully."
            });
          }, 1000);
        });
      }*/
};
