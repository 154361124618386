<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-form ref="busForm" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="localBus.reference"
                  :rules="normalRules"
                  label="Reference"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="localBus.brand"
                  :rules="normalRules"
                  label="Brand"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="localBus.year"
                  :rules="normalRules"
                  label="Model Year"
                  type="number"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="localBus.model"
                  :rules="normalRules"
                  label="Model"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="closeDialog"> Close </v-btn>
          <v-btn
            text
            color="primary"
            :disabled="clicked"
            @click="index === -1 ? saveBus() : updateBus()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/http.service.js";
export default {
  name: "AddBusDialog",
  props: {
    title: {
      type: String,
    },
    index: {
      type: Number,
      default: -1,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    busTerm: {
      type: String,
      default: "Bus",
    },
    bus: {
      type: Object,
      default: () => ({
        id: null,
        reference: "",
      }),
    },
  },
  data() {
    return {
      valid: false,
      clicked: false,
      localBus: { ...this.bus },
      normalRules: [
        (v) =>
          (v !== null && v !== undefined && v !== "") || "Field is required",
        (v) =>
          (v && v.length <= 50) || "Reference must be less than 50 characters",
      ],
    };
  },
  watch: {
    // Whenever the dialog opens, reset the form for a fresh start
    dialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.busForm.resetValidation();
          // Optionally clear any old input
        });
        this.localBus = { ...this.bus };
      } else {
        this.localBus = { id: null, reference: "" };
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-bus-dialog");
      this.localBus = { id: null, reference: "" };
    },
    async saveBus() {
      console.log(this.localBus);
      if (!this.$refs.busForm.validate()) {
        this.$snackbar.show("Please fill the form correctly.", "error");
        return;
      }

      this.clicked = true;
      try {
        const response = await axios.post("bus/add", {
          reference: this.localBus.reference,
          year: this.localBus.year,
          model: this.localBus.model,
          brand: this.localBus.brand,
        });

        if (response.data.responseCode === 1) {
          this.$emit("bus-added", response.data.data);
          this.$snackbar.show(`${this.busTerm} added successfully.`, "success");
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage || `Failed to add ${this.busTerm}`,
            "error"
          );
        }
      } catch (error) {
        this.$snackbar.show(
          `Error adding ${this.busTerm}: ${error.message}`,
          "error"
        );
      } finally {
        this.clicked = false;
      }
    },

    async updateBus() {
      if (!this.$refs.busForm.validate()) {
        this.$snackbar.show("Please fill the form correctly.", "error");
        return;
      }

      try {
        const response = await axios.post("bus/save", {
          id: this.localBus.id,
          reference: this.localBus.reference,
          year: this.localBus.year,
          model: this.localBus.model,
          brand: this.localBus.brand,
        });

        if (response.data.responseCode === 1) {
          this.$emit("bus-updated", response.data.data, this.index);
          this.$snackbar.show(
            `${this.busTerm} updated successfully.`,
            "success"
          );
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage ||
              `Failed to update ${this.busTerm}.`,
            "error"
          );
        }
      } catch (error) {
        console.error("Error details:", error);
        const errorMessage =
          error?.response?.data?.message ||
          error.message ||
          "An unknown error occurred";
        this.$snackbar.show(
          `Error updating ${this.busTerm}: ${errorMessage}`,
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
/* Your dialog-specific styles here */
</style>
