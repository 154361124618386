<template>
  <div class="overview">
    <div class="header">
      <div class="header-container">
        <div class="header-decoration">
          <div style="text-align: left">
            <h1>Good morning, {{ tenant_name }}!</h1>
            <p>Welcome to the #1 platform for fleet management</p>
          </div>

          <div style="position: relative; left: -140px;">
            <img
              class="titleimg"
              alt="Vue logo"
              src="@/assets/images/overviewheader1.svg"
              style="width: 710px; height: 370px"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="center">
      <div class="content">
        <div class="left-section">
          <div v-for="link in leftLinks" :key="link.name" class="link-item">
            <div class="link-icon">
              <img v-if="link.name === 'Create A Journey'" src="@/assets/images/createjourney.svg" class="svg-icons-overview-left" alt="Icon" />
              <img v-if="link.name === 'Dashboard & Map'" src="@/assets/images/dashboardmap.svg" class="svg-icons-overview-left" alt="" />
              <img v-if="link.name === 'API catalog'" src="@/assets/images/apimanager.svg" class="svg-icons-overview-left" alt="Icon" />
              <span v-else-if="link.name === 'Anypoint Code Builder'">C</span>
              <span v-else-if="link.name === 'Design Center'">G</span>
              <span v-else-if="link.name === 'Exchange'">X</span>
            </div>
            <div class="link-details">
              <h3 class="link-title">{{ link.name }}</h3>
              <p class="link-description">{{ link.description }}</p>
              <router-link v-if="link.name === 'API catalog'" :to="{ path: '/api-catalog' }" class="action-button">
                {{ link.action }}
              </router-link>
              <a v-else-if="link.action" :href="link.link" class="action-button">
                {{ link.action }}
              </a>
            </div>
          </div>
        </div>

        <div class="right-section">
          <h2
            style="
              margin-bottom: 25px;
              border-bottom: 1px solid #ccc;
              padding-bottom: 20px;
            "
          >
            Management Center
          </h2>

          <a
            v-for="link in rightLinks"
            :key="link.name"
            :href="link.link"
            class="link-item"
          >
          <div class="link-container">
            <div class="link-icon-management">
              <img v-if="link.name === 'Driver management'" src="@/assets/images/drivermanagement.svg" class="svg-icons-overview-left-management" alt="Icon" />
              <img v-if="link.name === 'Vehicle management'" src="@/assets/images/Vehiclemanagement.svg" class="svg-icons-overview-left-management" alt="Icon" />
              <img v-if="link.name === 'Exception management'" src="@/assets/images/exceptionmanagement.svg" class="svg-icons-overview-left-management" alt="Icon" />
              <img v-if="link.name === 'AI'" src="@/assets/images/aimanagement.svg" class="svg-icons-overview-left-management" alt="Icon" />
              <img v-if="link.name === 'Analytics'" src="@/assets/images/analyticsmanagement.svg" class="svg-icons-overview-left-management" alt="Icon" />
              <img v-if="link.name === 'Communication Channel'" src="@/assets/images/contactwireless.svg" class="svg-icons-overview-left-management" alt="Icon" />
            </div>
            <div class="link-details">
              <h3>{{ link.name }}</h3>
              <p>{{ link.description }}</p>
            </div>
            <v-icon class="chevron-right-icon">mdi-chevron-right</v-icon>
          </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "Bassem",
      tenant_name: sessionStorage.getItem("tenant_name") ?? "Admin",

      links: [
        {
          name: "Create A Journey",
          description: "Automate your custom workflow",
          link: "/trips",
          action: "Start",
        },
        {
          name: "Dashboard & Map",
          description: "See your daily business operations",
          link: "/dashboard",
          action: "Operations",
        },
        {
          name: "API catalog",
          description: "Discover and consume cooriroo APIs",
          link: "/api-catalog",
          action: "Explore",
        },
        {
          name: "Driver management",
          description: "Add, manage your driver",
          link: "/drivers",
          action: null,
        },
        {
          name: "Vehicle management",
          description: "Add, manage and monitor your vehicle",
          link: "/vehicles",
          action: null,
        },
        {
          name: "Exception management",
          description: "Monitor your exceptions and alerts",
          link: "/exceptions",
          action: null,
        },
        {
          name: "AI",
          description: "Train your AI with your business knowledge",
          link: "/ai",
          action: null,
        },
        {
          name: "Analytics",
          description: "Know more about your business and staff KPIs",
          link: "/analytics",
          action: null,
        },
        {
          name: "Communication Channel",
          description: "Communicate with your staff in realtime",
          link: "/push-notifications",
          action: null,
        },
      ],
      leftLinks: [],
      rightLinks: [],
    };
  },
  mounted() {
    // Split links into left and right sections
    this.leftLinks = this.links.slice(0, 3);
    this.rightLinks = this.links.slice(3);
  },
};
</script>

<style scoped>
.overview {
  font-family: sans-serif;
  padding: 0px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header-container {
  /* padding: 30px; */
  position: relative;
  max-height: 150px;
  min-height: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, rgb(255, 200, 84), rgb(249, 247, 247));
}

.header-decoration {
  /* Style your header decoration here */
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding-left: 250px;
  /* overflow: hidden; */
  max-height: 150px;
  height: 100%;
  /* margin-top: 10px; */
}

.header-decoration h1{
  font-size: 30px;
  font-weight: 600;
}

.content {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  gap: 50px;
  margin-top: 20px;
  width: 100%;
}

.center {
  display: flex;
  justify-items: center;
  justify-content: center;
  width: 100%;
}

.left-section {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-top: 30px;
  gap: 50px;
  max-width: 500px;
}

.right-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f3f3f3;
  padding: 30px;
  max-width: 500px;
  width: 100%;
  border-radius: 6px;
}

.link-item {
  display: flex;
  color: black;
  text-decoration: none;
  /* margin-bottom: 20px; */
}

.link-title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
}

.link-description {
  display: flex;
  /* margin-bottom: 20px; */
}

.link-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.link-icon-management {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.link-details {
  flex: 1;
}

.link-details p {
 font-size: 14px;
 color: rgb(63, 63, 63);
}

.link-container {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 6px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
}

.link-header {
  display: flex;
  align-items: center;
}

.link-header img {
  margin-right: 10px;
}

.link-container h3 {
  font-size: 16px;
}

.chevron-right-icon {
  margin-top: -15px;
}

.link-container:hover h3 {
  /* background-color: red;
  color: white;
  padding-left: 12px; */
  text-decoration: underline;
}

.action-button {
  background-color: transparent;
  border: 1px solid #e5e5e5;
  color: rgb(70, 70, 70);
  font-weight: 600;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 9999px;
}

.action-button:hover {
  background-color: #efeeee;
}

.titleimg {
  position: absolute;
  overflow: hidden;
  top: -130px;
  /* right: 340px; */
  width: 340px;
}

.svg-icons-overview-left {
  width: 40px;
  height: 40px;
  margin-top: -7px;
}
.svg-icons-overview-left-management {
  width: 30px;
  height: 30px;
  margin-top: -25px;
}
</style>