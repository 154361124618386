import api from "./api.js";
var bus_term = localStorage.getItem("bus_term") || "Vehicle";

export default {
  data: () => ({
    clicked: false,
    confrimword: null,
    selectedObject: { id: -1 },
    loading: true,
    confirmationDialog: false,
    search: "",
    bus_term: bus_term,
    json_fields: {
      Id: "id",
      Status: "driver_id",
      Reference: "reference",
    },
    items: [],
    myCurrentData: [],
    carousel_on: false,
    selectedRequest: null,
    olddata: [],
    pendingData: [],
    headers: [
      {
        text: "Driver",
        align: "center",
        value: "driver",
      },
      {
        text: "Old " + bus_term + " Reference",
        align: "center",
        value: "oldBus.reference",
      },
      {
        text: "New " + bus_term + " Reference",
        value: "newBus.reference",
        align: "center",
      },
      {
        text: "Issued Date",
        value: "creation_date",
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    api
      .getBusRequests()
      .then((res) => {
        this.loading = false;
        if (res.data.responseCode === 1) {
          this.olddata = [];
          this.pendingData = [];

          res.data.data.forEach((element) => {
            if (element.status === "0") this.pendingData.push(element);
            else {
              this.olddata.push(element);
            }
          });
        }
      })
      .catch((error) => {
        this.error = error;
        this.loading = false;
        this.errorDialog = true;
      });
  },
  methods: {
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    accept() {
      this.loading = true;
      api
        .accept(this.selectedRequest.id)
        .then((res) => {
          this.loading = false;
          this.confirmationDialog = false;
          if (res.data.responseCode === 1) {
            const index = this.pendingData.indexOf(5);
            this.pendingData.splice(index, 1);
            this.olddata.push(this.selectedRequest);
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    swap() {
      this.loading = true;
      api
        .swap(this.selectedRequest.id)
        .then((res) => {
          this.loading = false;
          this.confirmationDialog = false;
          if (res.data.responseCode === 1) {
            const index = this.pendingData.indexOf(5);
            this.pendingData.splice(index, 1);
            this.olddata.push(this.selectedRequest);
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    decline() {
      this.loading = true;
      api
        .decline(this.selectedRequest.id)
        .then((res) => {
          this.loading = false;
          this.confirmationDialog = false;
          if (res.data.responseCode === 1) {
            const index = this.pendingData.indexOf(5);
            this.pendingData.splice(index, 1);
            this.olddata.push(this.selectedRequest);
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    confirmation(confrimword, swap) {
      this.clicked = true;
      if (swap === true) {
        this.swap();
        this.clicked = false;
        return;
      }
      if (confrimword === "accept") {
        this.accept();
        this.clicked = false;
      } else {
        this.decline();
        this.clicked = false;
      }
    },
    showConfirmDialog(type, request) {
      this.selectedRequest = request;
      this.confirmationDialog = true;
      this.confrimword = type;
    },
    showcarousel(item) {
      this.items = [];
      api
        .getimages(item.id)
        .then((res) => {
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              this.items.push({
                src: this.$imgurl + "vehicle/" + element.title,
              });
            });
            this.carousel_on = true;
          } else {
            this.$snackbar.show("No images available.", "success");
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.errorDialog = true;
        });
    },
  },
  computed: {
    middleColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 1;
        }
        case "lg": {
          return 1;
        }
        case "xl": {
          return 2;
        }
      }
    },
    lastColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 4;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 1;
        }
        case "lg": {
          return 1;
        }
        case "xl": {
          return 1;
        }
      }
    },
    firstColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 6;
        }

        case "sm": {
          return 6;
        }

        case "md": {
          return 2;
        }
        case "lg": {
          return 2;
        }
        case "xl": {
          return 2;
        }
      }
    },
    secondColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 2;
        }
        case "lg": {
          return 2;
        }
        case "xl": {
          return 2;
        }
      }
    },
  },
};
