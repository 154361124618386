import api from "./api.js";
import settings from "../../plugins/settings.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";
var currency = settings.fromNameToSymbol(
  localStorage.getItem("currency") || "Euro"
);
var volume_unit = settings.fromNameToSymbol(
  localStorage.getItem("liquid_unit"),
  "liquidUnit"
);

export default {
  data: () => ({
    intervalId: null,
    loading: true,
    search: "",
    selectedObject: {},
    data: [],
    items: [],
    myCurrentData: [],
    carousel_on: false,
    deleteDialog: false,
    driver_term: driver_term,
    currency: currency,
    volume_unit: volume_unit,
    NormalRules: [(v) => !!v || "Field is required"],
    json_fields: {
      Id: "id",
      [`${driver_term} Name`]: "driver_id",
      Quantity: "volume",
      Price: "amount",
    },
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    dialog: false,
    show1: false,
    valid: false,
    amount: 0.0,
    volume: 0.0,
    clicked: false,
    headers: [
      {
        text: driver_term + " Name",
        align: "center",
        value: "driver_id",
      },

      {
        text: "Quantity ",
        align: "center",
        value: "volume",
      },
      {
        text: "Price",
        value: "amount",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
  async mounted() {
    window.addEventListener("keyup", this.handleKeyPress);
    var myCurrentDate = new Date();
    var myFutureDate = new Date();

    this.fdate = myCurrentDate.toISOString().substr(0, 10);
    this.tdate = myFutureDate.toISOString().substr(0, 10);

    await this.fetch();

    this.intervalId = setInterval(() => {
      this.fetch();
    }, 10000);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyPress);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        if (this.dialog) this.save();
        if (this.deleteDialog) this.agreeOnDelete();
      }
    },
    fetch() {
      api
        .gettanks(this.fdate, this.tdate + " 00:00:00")
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            this.data = [];

            res.data.data.forEach((element) => {
              element.driver_id =
                element?.driver?.fname + " " + element?.driver?.lname;
              this.data.push(element);
            });
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    showcarousel(item) {
      this.items = [];
      api
        .getimages(item.id)
        .then((res) => {
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              this.items.push({ src: this.$imgurl + "fuel/" + element.title });
            });
            this.carousel_on = true;
          } else {
            this.$snackbar.show("No images available.", "success");
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.errorDialog = true;
        });
    },
    showDialog(item) {
      this.valid = true;
      this.selectedObject = { ...item };
      this.dialog = true;
    },
    save() {
      this.clicked = true;
      if (!this.$refs.form.validate()) {
        this.clicked = false;
        return;
      }
      this.loading = true;
      console.log(this.selectedObject.amount);
      console.log(this.selectedObject.volume);

      api
        .save(
          this.selectedObject.id,
          this.selectedObject.amount,
          this.selectedObject.volume
        )
        .then((res) => {
          this.loading = false;
          this.dialog = false;
          this.clicked = false;
          if (res.data.responseCode === 1) {
            this.$snackbar.show("Diesel Tank updated successfully.", "success");
          } else {
            this.$snackbar.show(res.data.responseMessage, "success");
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.loading = false;
          this.dialog = false;
          this.clicked = false;
        });
    },
    onDelete(item) {
      this.selectedObject = item;
      this.deleteDialog = true;
    },
    agreeOnDelete() {
      this.clicked = true;
      this.loading = true;

      api
        .archive(this.selectedObject.id)
        .then((res) => {
          this.loading = false;

          this.deleteDialog = false;
          this.clicked = false;
          if (res.data.responseCode === 1) {
            this.$snackbar.show("Refuel deleted succesfully.", "success");
          } else {
            console.log(res.data.responseMessage);
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.loading = false;
          this.deleteDialog = false;
          this.clicked = false;
        });
      this.fetch();
    },
  },
};
