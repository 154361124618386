import api from "./api.js";
import settings from "@/plugins/settings.js";
import AddMaintenanceDialog from "@/components/dialogs/add_maintenance_dialog.vue";
import ViewMaintenanceHistoryDialog from "@/components/dialogs/view_maintenance_history_dialog.vue";

var bus_term = localStorage.getItem("bus_term") || "Vehicle";
var currency = settings.fromNameToSymbol(
  localStorage.getItem("currency") || "Euro"
);

export default {
  components: {
    AddMaintenanceDialog,
    ViewMaintenanceHistoryDialog,
  },
  data: () => ({
    intervalId: null,
    headers: [
      {
        text: bus_term + " Reference",
        align: "center",
        value: "vehicle_reference",
      },
      {
        text: "Predicted Upcoming Maintenance",
        align: "center",
        value: "predicted_upcoming_maintenance",
      },
      { text: "Last Maintenance Date", value: "service_date", align: "center" },
      { text: "Last Maintenance Notes", value: "last_note", align: "center" },
      {
        text: "Malfunctions Probability",
        value: "malfunction_probability",
        align: "center",
      },
      { text: "Actions", value: "actions", align: "center" },
    ],
    dialog: false,
    historyDialog: false,
    data: [],
    history: [],
    loading: false,
    search: "",
    currency: currency,
    bus_term: bus_term,
  }),
  async mounted() {
    await this.fetchMaintenance();

    this.intervalId = setInterval(() => {
      this.fetchMaintenance();
    }, 10000);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async fetchMaintenance() {
      api
        .getMaintenances()
        .then((res) => {
          if (res.data.success) {
            this.data = [];
            
            this.data = res.data.data.map((element) => {
              element.malfunction_probability =
                element.malfunction_probability + "%";
              element.last_note =
                element.last_note == ""
                  ? "No note to display"
                  : element.last_note;
              return element;
            });
          } else {
            console.error("Failed to fetch vehicles:", res.data);
          }
        })
        .catch((error) => {
          console.error("API error (fetchMaintennece):", error);
        });
    },

    fetchHistory(item) {
      api
        .getHistory(item.vehicle_id)
        .then((res) => {
          if (res.data.success) {
            this.history = res.data.data;
          } else {
            console.error("Failed to fetch vehicle history:", res.data);
          }
        })
        .catch((error) => {
          console.error("API error (fetchHistory):", error);
        });
    },

    onMaintenanceAdded() {
      this.fetchMaintenance();
      this.dialog = false;
    },

    viewHistory(item) {
      this.fetchHistory(item);
      this.historyDialog = true;
    },

    closeHistoryDialog() {
      this.historyDialog = false;
      this.history = [];
    },

    rescheduleMaintenance() {
      this.$router.push("/trips");
    },
  },
};
