<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-form ref="warehouseForm" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <GmapAutocomplete
                  :value="localWarehouse.title"
                  @input="localWarehouse.title = $event.target.value"
                  @place_changed="onPlaceSelectedWarehouse"
                  class="form-control"
                  placeholder="Start typing to find the address..."
                  style="width: 100%; padding: 10px; border: 1px solid black"
                  :rules="normalRules"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="closeDialog">Close</v-btn>
          <v-btn
            text
            color="primary"
            :disabled="clicked"
            @click="index === -1 ? saveWarehouse() : updateWarehouse()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/http.service.js";

export default {
  name: "AddWarehouseDialog",
  props: {
    title: {
      type: String,
      default: "Add Warehouse",
    },
    index: {
      type: Number,
      default: -1,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    warehouseTerm: {
      type: String,
      default: "Warehouse",
    },
    warehouse: {
      type: Object,
      default: () => ({
        id: null,
        title: "",
        lat: null,
        lon: null,
      }),
    },
  },
  data() {
    return {
      valid: false,
      clicked: false,
      localWarehouse: { ...this.warehouse },
      normalRules: [(v) => !!v || "Field is required"],
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.warehouseForm.resetValidation();
        });
        this.localWarehouse = { ...this.warehouse };
      } else {
        this.localWarehouse = { id: null, title: "", lat: null, lon: null };
      }
    },
  },
  methods: {
    onPlaceSelectedWarehouse(place) {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      this.localWarehouse.title = place.formatted_address;
      this.localWarehouse.lat = latitude;
      this.localWarehouse.lon = longitude;
    },
    closeDialog() {
      this.$emit("close-warehouse-dialog");
      this.localWarehouse = { id: null, title: "", lat: null, lon: null };
    },
    async saveWarehouse() {
      if (!this.$refs.warehouseForm.validate()) {
        this.$snackbar.show("Please fill the form correctly.", "error");
        return;
      }

      const { title, lat, lon } = this.localWarehouse;

      this.clicked = true;
      try {
        const response = await axios.post("warehouse/add", { title, lat, lon });

        if (response.data.responseCode === 1) {
          this.$emit("warehouse-added", response.data.data);
          this.$snackbar.show(
            `${this.warehouseTerm} added successfully.`,
            "success"
          );
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage ||
              `Failed to add ${this.warehouseTerm}.`,
            "error"
          );
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message ||
          error.message ||
          "An unknown error occurred";
        this.$snackbar.show(
          `Error adding ${this.warehouseTerm}: ${errorMessage}`,
          "error"
        );
      } finally {
        this.clicked = false;
      }
    },
    async updateWarehouse() {
      if (!this.$refs.warehouseForm.validate()) {
        this.$snackbar.show("Please fill the form correctly.", "error");
        return;
      }

      const { id, title, lat, lon } = this.localWarehouse;

      this.clicked = true;
      try {
        const response = await axios.post("warehouse/save", {
          id,
          title,
          lat,
          lon,
        });

        if (response.data.responseCode === 1) {
          this.$emit("warehouse-updated", response.data.data, this.index);
          this.$snackbar.show(
            `${this.warehouseTerm} updated successfully.`,
            "success"
          );
          this.closeDialog();
        } else {
          this.$snackbar.show(
            response.data.responseMessage ||
              `Failed to update ${this.warehouseTerm}.`,
            "error"
          );
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message ||
          error.message ||
          "An unknown error occurred";
        this.$snackbar.show(
          `Error updating ${this.warehouseTerm}: ${errorMessage}`,
          "error"
        );
      } finally {
        this.clicked = false;
      }
    },
  },
};
</script>

<style scoped>
/* Your dialog-specific styles here */
</style>
