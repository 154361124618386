<template>
  <div>
    <div class="center">
      <h1 class="title-main">Cooriroo API Catalog</h1>
      <div class="search-container">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div class="content">
        <div v-for="card in filteredCards" :key="card.name" class="card" @click="navigateTo(card.link)">
          <div class="card-header">
            <img :src="getHeaderIcon(card.provider)" class="svg-icons-overview-left" alt="Icon" />
            <span class="header-text">{{ card.type === 'Connector' ? 'Connector' : card.type + ' API' }}</span>
          </div>
          <div class="card-body">
            <img :src="card.icon" class="body-icon" alt="Icon" />
            <h3 class="card-title">{{ card.name }}</h3>
            <p class="card-provider">{{ card.provider }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      cards: [
        { name: "Journey", icon: require("../../assets/images/apimanager.svg"), link: "/journey", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Driver", icon: require("../../assets/images/apimanager.svg"), link: "/driver", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Vehicle", icon: require("../../assets/images/apimanager.svg"), link: "/vehicle", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Location", icon: require("../../assets/images/apimanager.svg"), link: "/location", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Destination", icon: require("../../assets/images/apimanager.svg"), link: "/destination", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Exception", icon: require("../../assets/images/apimanager.svg"), link: "/exception", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Process Approval", icon: require("../../assets/images/apimanager.svg"), link: "/process-approval", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Analytics", icon: require("../../assets/images/apimanager.svg"), link: "/analytics", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Refuel", icon: require("../../assets/images/apimanager.svg"), link: "/refuel", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Accident", icon: require("../../assets/images/apimanager.svg"), link: "/accident", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Communication", icon: require("../../assets/images/apimanager.svg"), link: "/communication", type: "REST", provider: "COORIROO TECHNOLOGIES L.L.C" },
        { name: "Salesforce", icon: require("../../assets/images/salesforce.svg"), link: "/communication", type: "Connector", provider: "Salesforce, Inc." },
        { name: "Hubspot", icon: require("../../assets/images/hubspot.svg"), link: "/communication", type: "Connector", provider: "HubSpot, Inc." },
      ],
    };
  },
  computed: {
    filteredCards() {
      return this.cards.filter(card => {
        return card.name.toLowerCase().includes(this.search.toLowerCase()) ||
               card.provider.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    navigateTo(link) {
      this.$router.push(link);
    },
    getHeaderIcon(provider) {
      switch (provider) {
        case "COORIROO TECHNOLOGIES L.L.C":
          return require("../../assets/images/apimanager.svg");
        case "Salesforce, Inc.":
          return require("@/assets/images/salesforce.svg");
        case "HubSpot, Inc.":
          return require("@/assets/images/hubspot.svg");
        default:
          return require("@/assets/images/apimanager.svg");
      }
    },
  },
};
</script>

<style scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title-main {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0px;
  text-transform: uppercase;
  color: #3c3c3c;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  padding: 0 20px;
}

.search-container .v-text-field {
  width: 100%;
}

@media (min-width: 1024px) {
  .search-container .v-text-field {
    width: 50%;
  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 170px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
}

.header-icon {
  margin-right: 5px;
}

.header-text {
  font-size: 13px;
  font-weight: normal;
  margin-left: 5px;
  text-transform: uppercase;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.card-title {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}

.card-provider {
  font-size: 14px;
  color: #888;
}

.svg-icons-overview-left {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%;
    height: auto;
  }
}
</style>