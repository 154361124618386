<template>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          View History
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
  
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="history"
            :loading="loading"
            loading-text="Loading history... Please wait"
            dense
          >
            <template v-slot:[`item.service_date`]="{ item }">
              {{ formatDate(item.service_date) }}
            </template>
            <template v-slot:[`item.completed_by`]="{ item }">
              <span class="font-weight-bold">{{ item.completed_by }}</span>
            </template>
          </v-data-table>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "ViewMaintenanceHistoryDialog",
    props: {
      dialog: Boolean,
      history: Array,
    },
    data() {
      return {
        loading: false,
        headers: [
          { text: 'Service Date', value: 'service_date' },
          { text: 'Type', value: 'type' },
          { text: 'Note', value: 'note' },
          { text: 'Completed By', value: 'completed_by' }
        ]
      };
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      }
    }
  };
  </script>