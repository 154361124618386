import axios from "@/plugins/http.service.js";

export default {
  send(title, body, driver_id) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    var data = {
      title: title,
      body: body,
      driver_id: driver_id,
      tenant_id: tenant_id,
    };
    return axios.post("device/send-notification", data);
  },
  sendAudio(driver_id, audio_blob, language) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");
    var data = {
      driver_id: driver_id,
      tenant_id: tenant_id,
      audio: audio_blob,
      language: language,
    };
    return axios.post("device/send-voice-notification", data);
  },
  getdrivers() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`driver/get-drivers?tenant_id=${tenant_id}`);
  },
  getNotifications() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`notification/get-notifications?tenant_id=${tenant_id}`);
  },
  getLanguages(){
    return axios.get(`language/get-languages`);

  }
};
