import Vue from "vue";

import * as VueGoogleMaps from "vue2-google-maps";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Mainlayout from "./layouts/Mainlayout.vue";
import Defaultlayout from "./layouts/Defaultlayout.vue";
import Homelayout from "./layouts/Homelayout.vue";
import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";
import Vuex from "vuex";
import SnackbarPlugin from './plugins/snackbar';
import globalVars from '@/plugins/globalVars';


Vue.component("mainlayout", Mainlayout);
Vue.component("defaultlayout", Defaultlayout);
Vue.component("homelayout", Homelayout);
Vue.config.productionTip = false;
import excel from "vue-excel-export";
// Import the functions you need from the SDKs you need

Vue.use(Vuex);
Vue.use(DatetimePicker);
Vue.use(excel);
Vue.use(SnackbarPlugin);
Vue.prototype.$imgurl = process.env.VUE_APP_IMG_URL;
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

new Vue({
  router,
  store,
  Vuex,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
require("@/assets/css/style.css");

// DEFINE GLOBAL VARS
Vue.prototype.$globalVars = globalVars
