import api from "./api.js";
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  ArcElement,
  CategoryScale
);

var driver_term = localStorage.getItem("driver_term") || "Driver Name";
var destination_term =
  localStorage.getItem("destination_term") || "Destination";

export default {
  components: { PieChart: Pie },
  data: () => ({
    statusFilter: null,
    statusOptions: [
      { text: "All", value: null },
      { text: "Awaiting Submission", value: "awaiting_submission" },
      { text: "Pending", value: "pending" },
      { text: "Accepted", value: "accepted" },
      { text: "Rejected", value: "rejected" },
    ],
    approvals: [],
    currentManagerPage: 1,
    currentClientPage: 1,
    pageSizeManager: 10,
    totalManagerItems: 0,
    pageSizeClient: 10,
    totalClientItems: 0,
    selectedImages: [],
    options: {},
    clicked: false,
    selectedObject: { id: -1 },
    loading: true,
    confirmationDialog: false,
    searchManager: "",
    searchClient: "",
    filteredClients: [],
    pieChartData: {
      labels: ["Pending", "Accepted", "Rejected", "Awaiting Submission"],
      datasets: [
        {
          backgroundColor: ["#FFEB3B", "#4CAF50", "#F44336", "#FFA500"],
          data: [0, 0, 0, 0],
        },
      ],
    },
    chartOptions: {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
        },
      },
    },
    json_fields: {
      Id: "id",
      Status: "driver_id",
      Reference: "reference",
    },
    items: [],
    myCurrentData: [],
    selectedRequest: null,
    loading_manager_request: true,
    current_manager_requests: [],
    selectedManagerRequest: null,
    driver_term: driver_term,
    loading_client: false,
    loading_manager: false,
    imageDialog: false,
    manager_headers: [
      {
        text: driver_term,
        align: "center",
        value: "driver.full_name",
      },
      {
        text: "Destination Name",
        align: "start",
        value: "trip_destinations.destination_name",
      },
      /*{
        text: "Approval Type",
        value: "manager_approval",
        align: "start",
      },*/
      // {
      //   text: "Task",
      //   value: "task",
      //   align: "center",
      // },
      {
        text: "Task",
        value: "description",
        align: "start",
      },
      {
        text: "Approval Status",
        value: "manager_approval_status",
        align: "start",
      },
      {
        text: "Time Requested",
        value: "created_date",
        align: "start",
      },
      {
        text: driver_term + " Note",
        value: "note",
        align: "start",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
    client_headers: [
      {
        text: driver_term,
        align: "center",
        value: "driver.full_name",
      },
      {
        text: "Destination Name",
        align: "start",
        value: "trip_destinations.destination_name",
      },
      {
        text: "Task",
        value: "description",
        align: "start",
      },
      // {
      //   text: "Task",
      //   value: "task",
      //   align: "center",
      // },
      {
        text: "Time Requested",
        value: "created_date",
        align: "start",
      },
      {
        text: driver_term + " Note",
        value: "note",
        align: "start",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
    imagesDialog: false,
    approvalDialog: false,
    approvalStatus: null,
    approvalDescription: "",
    itemToShow: [],
    counts: { pending: 0, accepted: 0, rejected: 0, awaiting_submission: 0 },
    autoRefreshIntervalManager: null,
    autoRefreshIntervalClient: null,
    filteredManagerApprovals: [],
    filteredClientApprovals: [],
    selectedStatus: "pending",
  }),
  mounted() {
    this.autoRefreshIntervalManager = setInterval(
      this.refreshManagerDashboard,
      30000
    );
    this.autoRefreshIntervalClient = setInterval(
      this.refreshClientDashboard,
      30000
    );
  },

  beforeDestroy() {
    clearInterval(this.autoRefreshIntervalManager);
    clearInterval(this.autoRefreshIntervalClient);
  },
  methods: {
    filterByStatus(status) {
      this.selectedStatus = status;
      this.fetchManagerRequest();
    },
    onPaginationManager(options) {
      this.currentManagerPage = options.page;
      this.pageSizeManager = options.itemsPerPage;
      this.fetchManagerRequest();
    },
    onPaginationClient(options) {
      this.currentClientPage = options.page;
      this.pageSizeClient = options.itemsPerPage;
      this.fetchClientRequest();
    },
    async fetchManagerRequest() {
      this.loading_manager = true;
      api
        .getManagerApproval(
          this.selectedStatus ? this.selectedStatus : "",
          this.currentManagerPage,
          this.pageSizeManager,
          this.searchManager
        )
        .then((res) => {
          if (res.data.success) {
            if (!res.data.data) this.filteredManagerApprovals = [];
            else {
              this.filteredManagerApprovals = res.data.data
                .map((approval) => {
                  return {
                    ...approval,
                    driver: {
                      full_name:
                        approval.driver.fname + " " + approval.driver.lname,
                    },
                    destination_name:
                      approval.destination_name &&
                      approval.destination_name !== "0"
                        ? approval.destination_name
                        : "Unknown " + destination_term,
                    created_date: this.format_date(approval.created_date),
                    manager_approval_status: approval.manager_approval_status
                      .replaceAll("_", " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase()),
                    note:
                      !approval.note || approval.note == ""
                        ? "No note provided"
                        : approval.note,
                  };
                })
                .reverse();
            }

            this.totalManagerItems = res.data.pagination.total;
            this.updateCounts();
          }
          this.loading_manager = false;
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.errorDialog = true;
          this.loading_manager = false;
        });
    },

    async fetchClientRequest() {
      this.loading_client = true;
      api
        .getClientApproval(
          this.currentClientPage,
          this.pageSizeClient,
          this.searchClient
        )
        .then((res) => {
          if (res.data.success) {
            if (!res.data.data) {
              this.filteredClientApprovals = [];
            } else {
              this.filteredClientApprovals = res.data.data
                .map((approval) => {
                  return {
                    ...approval,
                    destination_name:
                      approval.destination_name &&
                      approval.destination_name !== "0"
                        ? approval.destination_name
                        : "Unknown " + destination_term,
                    driver: {
                      full_name:
                        approval.driver.fname + " " + approval.driver.lname,
                    },
                    created_date: this.format_date(approval.created_date),
                    note:
                      !approval.note || approval.note == ""
                        ? "No note provided"
                        : approval.note,
                  };
                })
                .reverse();
            }

            this.totalClientItems = res.data.pagination.total;
          }
          this.loading_client = false;
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.errorDialog = true;
          this.loading_client = false;
        });
    },

    format_date(dateInput) {
      if (!dateInput) return null;

      let date;

      if (typeof dateInput === "string") {
        date = new Date(dateInput);
      } else if (dateInput instanceof Date) {
        date = dateInput;
      } else {
        return null;
      }

      if (isNaN(date.getTime())) {
        return null;
      }

      return date.toLocaleString();
    },

    refreshManagerDashboard(fromIcon = "") {
      if (fromIcon == "icon") {
        this.searchManager = "";
      }
      this.loading_manager = true;
      // Fetch today's approvals and update the table
      this.fetchManagerRequest().finally(() => {
        this.loading_manager = false;
      });
    },

    refreshClientDashboard(fromIcon = "") {
      if (fromIcon == "icon") {
        this.searchClient = "";
      }
      this.loading_client = true;

      this.fetchClientRequest().finally(() => {
        this.loading = false;
      });
    },

    updateCounts() {
      const counts = {
        pending: 0,
        accepted: 0,
        rejected: 0,
        awaiting_submission: 0,
      };
      api.getApprovalStatusSummary().then((res) => {
        if (res.data.success) {
          counts.pending = res.data.data.pending;
          counts.awaiting_submission = res.data.data.awaiting_submission;
          counts.rejected = res.data.data.rejected;
          counts.accepted = res.data.data.accepted;

          this.pieChartData = {
            labels: ["Pending", "Accepted", "Rejected", "Awaiting Submission"],
            datasets: [
              {
                backgroundColor: ["#FFEB3B", "#4CAF50", "#F44336", "#FFA500"],
                data: [
                  counts.pending,
                  counts.accepted,
                  counts.rejected,
                  counts.awaiting_submission,
                ],
              },
            ],
          };
        }
      });
      this.counts = counts;
    },

    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },

    async updateRequest() {
      api
        .updateManagerRequest(this.selectedManagerRequest, this.approvalStatus)
        .then((res) => {
          if (res.data.responseCode) {
            this.refreshManagerDashboard();
            this.approvalDialog = false;
            this.$snackbar.show("Approval updated successfully", "success");
          }
        })
        .catch((error) => {
          this.$snackbar.show(error, "error");
          this.errorDialog = true;
          this.approvalDialog = false;
        });

      this.approvalDialog = false;
    },

    openImageModal(images) {
      if (images) {
        this.itemToShow = Array.isArray(images) ? images : [images];
      } else {
        this.itemToShow = [];
      }
      this.imageDialog = true;
    },

    openApprovalModal(item) {
      this.itemToShow = item;
      this.selectedManagerRequest = item.id;
      this.approvalDialog = true;
    },
  },
  computed: {
    middleColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }
        case "sm": {
          return 12;
        }
        case "md": {
          return 1;
        }
        case "lg": {
          return 1;
        }
        case "xl": {
          return 2;
        }
      }
    },
    lastColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 4;
        }
        case "sm": {
          return 4;
        }
        case "md": {
          return 1;
        }
        case "lg": {
          return 1;
        }
        case "xl": {
          return 1;
        }
      }
    },
    firstColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 6;
        }
        case "sm": {
          return 6;
        }
        case "md": {
          return 2;
        }
        case "lg": {
          return 2;
        }
        case "xl": {
          return 2;
        }
      }
    },
    secondColumn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }
        case "sm": {
          return 12;
        }
        case "md": {
          return 2;
        }
        case "lg": {
          return 2;
        }
        case "xl": {
          return 2;
        }
      }
    },
  },
};
