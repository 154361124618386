import axios from "@/plugins/http.service.js";

export default{
    getMaintenances(){
        return axios.get("/v1/vehicles/maintenances")
    },
    getHistory(vehicle_id){
        return axios.get(`v1/vehicles/${vehicle_id}/maintenances`)
    }
    
}