
import { ref, onMounted, onBeforeUnmount } from 'vue'
import Chart from 'chart.js/auto'
import api from "./api.js";

var bus_term = localStorage.getItem("bus_term") || "Vehicle";

export default {
  name: 'Dashboard',
  data(){
    return{
      bus_term: bus_term,
      tableHeaders: [
        { text: "Rank", value: "rank", align: "center" },
        { text: `${bus_term} Reference`, value: "reference", align: "center" },
        { text: "Malfunction Probability", value: "probability", align: "center" },
        { text: "Model Confidence", value: "modelConfidence", align: "center" },
        { text: "Key Risk Factors", value: "keyFactors", align: "center" },
      ],  
      vehicleHeaders: [
        { text: "Reference", value: "reference", align: "center" },
        { text: `Model`, value: "model", align: "center" },
        { text: "Year", value: "year", align: "center" },
        { text: "Brand", value: "brand", align: "center" },
      ], 
      fleetRiskRanking: [
        { rank: 1, reference: 'V045', probability: 92, modelConfidence: 88, keyFactors: 'High Mileage, Poor Service History' },
        { rank: 2, reference: 'V012', probability: 87, modelConfidence: 90, keyFactors: 'Multiple Reported Issues, High Mileage' },
        { rank: 3, reference: 'V001', probability: 75, modelConfidence: 90, keyFactors: 'High Mileage, Poor Maintenance History' },
        { rank: 4, reference: 'V023', probability: 72, modelConfidence: 85, keyFactors: 'Age >10 years, Brake Issues' },
        { rank: 5, reference: 'V030', probability: 70, modelConfidence: 88, keyFactors: 'Multiple Brake Failures' },
        { rank: 6, reference: 'V054', probability: 67, modelConfidence: 86, keyFactors: 'Past Critical Failures' },
        { rank: 7, reference: 'V065', probability: 65, modelConfidence: 89, keyFactors: 'Excessive Engine Heat' },
        { rank: 8, reference: 'V069', probability: 62, modelConfidence: 81, keyFactors: 'Excessive Engine Heat' },
        { rank: 9, reference: 'V066', probability: 61, modelConfidence: 87, keyFactors: 'Excessive Engine Heat' },
        { rank: 10, reference: 'V091', probability: 60, modelConfidence: 76, keyFactors: 'Excessive Engine Heat' },
        { rank: 11, reference: 'V100', probability: 20, modelConfidence: 76, keyFactors: 'Excessive Engine Heat' },
      ],
      highRiskCount: 9,
      lastUpdated: new Date().toLocaleString(),
      showAvailableVehiclesDialog: false,
      availableVehiclesList: []
    }
  },
  mounted(){
    api.getbuses().then((res)=>{
        if(res.data.responseCode){
            this.availableVehiclesList = res.data.data;
        }else{
            this.$snackbar("Failed to fetch vehicles")
        }
    }).catch((err) =>{
        console.log("Error on getbuses: ", err);
    });
  },
  setup() {
    // Refs to the canvas elements for charts
    const riskDistributionChart = ref(null)
    const lineChart = ref(null)

    // Chart instances for cleanup
    let riskDistributionChartInstance = null
    let lineChartInstance = null

    // ------------------------
    // Expanded Sample Data (Replace with API Calls in real implementation)
    // ------------------------

    // Expanded Risk Distribution Data
    const riskDistributionData = [12, 30, 18] // More detailed counts for risk levels

    // Improved KPIs
    const fleetAverageProbability = ref(68)

    // Updated Model Performance Metrics
    const modelPerformance = ref({
      accuracy: 0.86,
      precision: 0.81,
      recall: 0.79,
      f1Score: 0.80,
      rocAuc: 0.89
    })

    // Expanded Time Trend Data (More months for better trend analysis)
    const timeTrendData = ref({
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      values: [60.8, 62, 65, 20, 68, 90, 75, 78, 10, 80] // A progressive trend
    })

    // More detailed Key Feature Impact
    const keyFeatureImpact = ref([
      { feature: 'Mileage', score: 0.25 },
      { feature: 'Reported Issues', score: 0.22 },
      { feature: 'Vehicle Age', score: 0.18 },
      { feature: 'Maintenance Gaps', score: 0.12 },
      { feature: 'Fuel System Condition', score: 0.10 },
      { feature: 'Engine Temperature Variations', score: 0.08 },
      { feature: 'Brake Wear', score: 0.05 }
    ])

    const maintenanceCostBreakdown = ref([
      { category: 'Preventive Maintenance', cost: 12000 },
      { category: 'Corrective Repairs', cost: 10500 },
      { category: 'Spare Parts Replacement', cost: 5000 },
      { category: 'Labor Costs', cost: 4000 }
    ]);
    
    const totalMaintenanceCost = ref(
      maintenanceCostBreakdown.value.reduce((sum, item) => sum + item.cost, 0)
    );
    
    // ------------------------
    // Chart Initialization
    // ------------------------
    onMounted(() => {
      // Risk Distribution Chart
      if (riskDistributionChart.value) {
        riskDistributionChartInstance = new Chart(riskDistributionChart.value, {
          type: 'bar',
          data: {
            labels: ['0–30%', '31–70%', '71–100%'],
            datasets: [
              {
                label: 'Risk Distribution',
                data: riskDistributionData,
                backgroundColor: ['#4CAF50', '#FFC107', '#F44336']
              }
            ]
          },
          options: {
            responsive: true,
            plugins: {
              legend: { display: true }
            }
          }
        })
      }

      // Time Trend (Line) Chart
      if (lineChart.value) {
        lineChartInstance = new Chart(lineChart.value, {
          type: 'line',
          data: {
            labels: timeTrendData.value.labels,
            datasets: [
              {
                label: 'Avg Malfunction Probability Over Time',
                data: timeTrendData.value.values,
                borderColor: '#42A5F5',
                backgroundColor: 'rgba(66, 165, 245, 0.2)',
                fill: true
              }
            ]
          },
          options: {
            responsive: true,
            plugins: {
              legend: { display: true }
            },
            scales: {
              y: {
                beginAtZero: true,
                max: 100
              }
            }
          }
        })
      }
    })

    // ------------------------
    // Cleanup on Unmount
    // ------------------------
    onBeforeUnmount(() => {
      if (riskDistributionChartInstance) {
        riskDistributionChartInstance.destroy()
      }
      if (lineChartInstance) {
        lineChartInstance.destroy()
      }
    })

    const availabilityRate = 30

    // ------------------------
    // Return references
    // ------------------------
    return {
      // Refs
      riskDistributionChart,
      lineChart,
      // Data
      fleetAverageProbability,
      modelPerformance,
      availabilityRate,
      timeTrendData,
      keyFeatureImpact,
      maintenanceCostBreakdown,
      totalMaintenanceCost
    }
  },
  methods: {
    getRiskColor(probability) {
      if (probability > 70) return "rgba(244, 67, 54, 0.3)"; // Light red
      if (probability > 30) return "rgba(255, 152, 0, 0.3)"; // Light orange
      return "rgba(76, 175, 80, 0.3)"; // Light green
    },
    refreshData() {
      // Simulate a data refresh
      this.lastUpdated = new Date().toLocaleString();
      // Add any additional refresh logic here
    },
    formatCurrency(value) {
      return '$' + Number(value).toLocaleString();
    }
  }
}